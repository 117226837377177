import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WorkspaceDropdownLayoutComponent } from '@penji/client-v3/workspace/ui/workspace-dropdown-layout';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

interface CateType {
  cate: string;
  data: NavItem[];
}
interface NavItem {
  title: string;
  link: string;
  icon: string;
  items?: CateType[];
  param?: {};
  tag?: string;
}
@Component({
  selector: 'penji-sider',
  standalone: true,
  imports: [
    CommonModule,
    NzLayoutModule,
    NzMenuModule,
    RouterModule,
    NzToolTipModule,
    WorkspaceDropdownLayoutComponent
  ],
  templateUrl: './sider.component.html',
  styleUrls: ['./sider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiderComponent {

  @Input() siderCollapsed = false;

  // menu:
  items: NavItem[] = [
    // {
    //   title: 'Dashboard',
    //   link: './dashboard',
    //   icon: 'grid_view',
    // },
    // {
    //   title: 'Workflows',
    //   link: './workflows',
    //   icon: 'schema',
    // },
    // {
    //   title: 'Project',
    //   link: './projects/all',
    //   icon: 'content_paste',
    //   items: [
    //     {
    //       cate: 'Design',
    //       data: [
    //         {
    //           title: 'Unlimited',
    //           link: './projects/unlimited',
    //           icon: 'all_inclusive',
    //         },
    //         {
    //           title: 'Marketplace',
    //           link: './projects/marketplace',
    //           icon: 'shopping_cart',
    //         },
    //         // {
    //         //   title: 'Premium',
    //         //   link: './projects/premium',
    //         //   icon: 'diamond',
    //         // },
    //         // {
    //         //   title: 'AI Design',
    //         //   link: './projects/ai-design',
    //         //   icon: 'auto_fix_high',
    //         //   tag: 'Soon'
    //         // },
    //       ],
    //     },
    //     // {
    //     //   cate: 'Other Services',
    //     //   data: [
    //     //     {
    //     //       title: 'Social Media',
    //     //       link: './projects/social-media',
    //     //       icon: 'image',
    //     //     },
    //     //     {
    //     //       title: 'Content Writing',
    //     //       link: './projects/content-writing',
    //     //       icon: 'text_fields',
    //     //     },
    //     //   ]
    //     // }
    //   ],
    // },
    {
      title: 'Projects',
      link: './projects/unlimited',
      icon: 'content_paste',
    },
    {
      title: 'Brands',
      link: './brands',
      icon: 'folder_open',
    },
    {
      title: 'Teams',
      link: './teams',
      icon: 'people',
      param: { status: '1,2' }
    },
    // {
    //   title: 'Discover',
    //   link: './discover',
    //   icon: 'auto_graph',
    // },
    // {
    //   title: 'Courses',
    //   link: './courses',
    //   icon: 'book',
    // },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

}
