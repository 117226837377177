import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Membership, Team } from '@penji/shared/data-access';
import * as firebase from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MomentModule } from 'ngx-moment';

@Component({
  selector: 'penji-workspace-status',
  standalone: true,
  imports: [CommonModule, NzToolTipModule, MomentModule],
  templateUrl: './workspace-status.component.html',
  styleUrls: ['./workspace-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceStatusComponent {
  /*
    * new: KH mới đăng ký đc 1 tuần
    * active: KH đang active, ko phải new, hibernating  (active = 1 && created_at < now-7 && lastLogin > now-15 )
    * hibernating: Kh đang active và chưa login trong 15 ngày.
    * renew: kh còn >7 ngày tới ngày renew 
    * cancel: KH ko có membership nào active
    * last login tooltip
    * next billing tooltip
  */
  @Input() workspace?: Team;
  @Input() classes = '';
  @Input() move_right = false;  

  now = firebase.Timestamp.now();
  status = '';
  color$ = new BehaviorSubject<string>('bg-[#3B82F6]');
  lastLogin?: firebase.Timestamp;
  nextBill?: firebase.Timestamp;
  canceledTime?: firebase.Timestamp;

  ngOnChanges(): void {
    this.status = '';
    if (this.workspace) {
      // New
      if (typeof this.workspace.created_at === 'number') {
        const created_at = firebase.Timestamp.fromDate(new Date(this.workspace.created_at as any));
        const created_at_7days = firebase.Timestamp.fromDate(new Date(this.now.toDate().setDate(this.now.toDate().getDate() - 7)));
        if (created_at > created_at_7days) {
          this.status = 'new';
          this.color$.next('bg-[#3B82F6]');
        }
      } else {
        const created_at_7days = firebase.Timestamp.fromDate(new Date(this.now.toDate().setDate(this.now.toDate().getDate() - 7)));
        if (this.workspace.created_at && this.workspace.created_at > created_at_7days) {
          this.status = 'new';
          this.color$.next('bg-[#3B82F6]');
        }
      }
      if (this.workspace.membership && Array.isArray(this.workspace.membership)) {
        // console.log(typeof this.workspace.membership, this.workspace.membership);
        if (this.status === '') {
          if (this.workspace.membership.length > 0)
            this.getMemberShip(this.workspace, this.workspace.membership[0]);
          else {
            this.status = 'canceled';
            this.color$.next('bg-[#FA4D56]');
          }
        } else {
          if (this.workspace.membership.length > 0)
            this.nextBill = this.workspace.membership[0].end_date as any;
          else {
            this.status = 'canceled';
            this.color$.next('bg-[#FA4D56]');
          }
        }
      } else {
        if (this.workspace.membership && typeof this.workspace.membership === 'object') {
          if (this.status === '') {
            this.getMemberShip(this.workspace, this.workspace.membership);

          } else {
            const mship = this.workspace.membership as any;
            if (typeof mship.end_date == 'number') {
              this.nextBill = firebase.Timestamp.fromDate(new Date(mship.end_date as any));
            }
          }
        }
      }
    }
  }

  getMemberShip(workspace: Team, membership: Membership) {
    //hibernating
    if (typeof workspace.lastLogin === 'number') {
      const last_login = firebase.Timestamp.fromDate(new Date(workspace.lastLogin as any));
      const now_15days = firebase.Timestamp.fromDate(new Date(this.now.toDate().setDate(this.now.toDate().getDate() - 15)));
      if ((membership as any)['active'] == 1 && last_login < now_15days) {
        this.status = 'hibernate';
        this.color$.next('bg-[#F97316]');
        this.lastLogin = last_login;
      };
    } else {
      const last_login = workspace.lastLogin as any;
      const now_15days = firebase.Timestamp.fromDate(new Date(this.now.toDate().setDate(this.now.toDate().getDate() - 15)));
      if ((membership as any)['active'] == 1 && last_login < now_15days) {
        this.status = 'hibernate';
        this.color$.next('bg-[#F97316]');
        this.lastLogin = last_login;
      };
    }
    // active
    if (membership.active == 1) {
      if (this.status == '') {
        this.status = 'active';
        this.color$.next('bg-[#24A148]');
        if (typeof membership.end_date === 'number') {
          const end_date = firebase.Timestamp.fromDate(new Date(membership.end_date as any));
          const end_date_7days = firebase.Timestamp.fromDate(new Date(this.now.toDate().setDate(this.now.toDate().getDate() + 7)));
          if (end_date > this.now && end_date < end_date_7days) {
            this.status = 'renew';
            this.color$.next('bg-[#8B5CF6]');
          }
          this.nextBill = end_date;
        } else {
          const end_date = membership.end_date as any;
          const end_date_7days = firebase.Timestamp.fromDate(new Date(this.now.toDate().setDate(this.now.toDate().getDate() + 7)));
          if (end_date > this.now && end_date < end_date_7days) {
            this.status = 'renew';
            this.color$.next('bg-[#8B5CF6]');

          }
          this.nextBill = end_date;
        }

      }
      //cancel
    } else if (membership.active == 0) {
      this.status = 'canceled';
      this.color$.next('bg-[#FA4D56]');
      if (typeof membership.end_date === 'number') {
        this.canceledTime = firebase.Timestamp.fromDate(new Date(membership.end_date as any));
      } else {
        this.canceledTime = membership.end_date as any;
      }
      // past due
    } else if (membership.active == 2) {
      this.status = 'past due';
      this.color$.next('bg-[#FA4D56] text-[10px]');
    }
  }
}
