export class Permission {
    'id'?: string;
    '_permission-feature'?: boolean;
    '_workspace'?: boolean;
    '_customer'?: boolean;
    '_member'?: boolean;
    '_project'?: boolean;
    '_designer-team'?: boolean;
    '_squad-group'?: boolean;
    '_category'?: boolean;
    '_ticket'?: boolean;
    '_checkin'?: boolean;
    '_request-off'?: boolean;
    '_logs'?: boolean;
    '_overtime'?: boolean;
    '_file-manager'?: boolean;
    '_view-design'?: boolean;
    '_template-answer'?: boolean;
    '_style-preference'?: boolean;
    '_affiliate'?: boolean;
    '_widget'?: boolean;
    '_stripe'?: boolean;
    '_disable-feature'?: boolean;
    '_booking-call'?:boolean;
    'extra-action'?: {
        '_designer-team-create'?: boolean,
        '_designer-team-update'?: boolean,
        '_designer-team-delete'?: boolean,
        '_squad-group-create'?: boolean,
        '_squad-group-update'?: boolean,
        '_squad-group-delete'?: boolean,
        '_project-create'?: boolean,
        '_project-edit'?: boolean,
        '_project-delete'?: boolean,
        '_project-review'?: boolean,
        '_project-change-status'?: boolean,
        '_project-manual-assign-designers'?: boolean,
        '_project-verify-designs'?: boolean,
        '_project-upload-designs'?: boolean,
        '_project-mask-feature'?: boolean,
        '_project-edit-anyone-comments'?: boolean,
        '_project-delete-anyone-comments'?: boolean,
        '_customer-view-email'?: boolean,
        '_customer-update-account'?: boolean,
        '_member-create'?: boolean,
        '_member-update'?: boolean,
        '_member-disable'?: boolean,
        '_member-schedule'?: boolean,
        '_checkin-create'?: boolean,
        '_checkin-update'?: boolean,
        '_checkin-delete'?: boolean,
        '_request-off-create'?: boolean,
        '_request-off-update'?: boolean,
        '_request-off-delete'?: boolean,
        '_request-off-approve'?: boolean,
        '_overtime-create'?: boolean,
        '_overtime-update'?: boolean,
        '_overtime-delete'?: boolean,
        '_overtime-approve'?: boolean,
        '_payroll-update'?: boolean,
        '_stripe-allow-reactive-subscription': boolean,
        '_ticket-create'?: boolean,
        '_ticket-update'?: boolean,
        '_ticket-delete'?: boolean,
        '_widget-customer-new-thirty-days'?: boolean,
        '_widget-member-by-squad'?: boolean,
        '_widget-member-by-team': false,
        '_widget-overtime'?: boolean,
        '_widget-project-by-designer'?: boolean,
        '_widget-project-by-squad'?: boolean,
        '_widget-project-by-team'?: boolean,
        '_widget-request-off'?: boolean,
        '_widget-request-off-calendar'?: boolean,
        '_widget-upcoming-off'?: boolean,
        '_widget-watched-customer'?: boolean,
        '_widget-unclaimed-ticket'?: boolean,
        '_widget-squad-by-team'?: boolean,
        '_disable-feature-search-bar'?:boolean,
        '_disable-feature-select-designer'?:boolean,
        '_disable-feature-select-team'?:boolean,
        '_disable-feature-select-squad-group'?:boolean,
        '_disable-feature-filter-workspace'?:boolean,
        '_workspace-manual-assign-smm'?: boolean
    }
    'log_type' = 'permission';
    static parseObject(data: any) {
        const transform: any = {};
        for (const key in data) {
            const value = data[key];
            if (typeof data[key] === 'object') {
                for (const keyDeep in data[key]) {
                    if (key === 'extra-action') {
                        transform[`extra-action.${keyDeep}`] = data[key][keyDeep];
                    } else {
                        transform[key] = value;
                    }
                }
            } else {
                transform[key] = value;
            }
        }
        return transform;
    }
}
export type permission_type = keyof typeof initPermission['extra-action'];
export const initPermission = {
    '_permission-feature': false,
    '_workspace': false,
    '_customer': false,
    '_member': false,
    '_project': false,
    '_designer-team': false,
    '_squad-group': false,
    '_category': false,
    '_ticket': false,
    '_checkin': false,
    '_request-off': false,
    '_logs': false,
    '_overtime': false,
    '_file-manager': false,
    '_view-design': false,
    '_template-answer': false,
    '_style-preference': false,
    '_affiliate': false,
    '_widget': false,
    '_stripe': false,
    '_disable-feature':false,
    '_booking-call':false,
    'extra-action': {
        '_designer-team-create': false, // chua co button
        '_designer-team-update': false, // chua co button
        '_designer-team-delete': false, // chua co button
        '_squad-group-create': false, // chua co button
        '_squad-group-update': false, // done
        '_squad-group-delete': false, // chua co button
        '_project-create': false, // chua co button
        '_project-edit': false, // done
        '_project-delete': false, // done
        '_project-review': false, // done
        '_project-change-status': false, // done
        '_project-manual-assign-designers': false,  // done
        '_project-verify-designs': false, // done
        '_project-upload-designs': false, // done
        '_project-mask-feature': false, // done
        '_project-edit-anyone-comments': false,
        '_project-delete-anyone-comments': false,
        '_customer-view-email': false,
        '_customer-update-account': false, // done
        '_member-create': false,// chua co button
        '_member-update': false,// chua co button
        '_member-disable': false,// chua co button
        '_member-schedule': false,// chua co button
        '_checkin-create': false, // done
        '_checkin-update': false, // done
        '_checkin-delete': false, // done
        '_request-off-create': false, // done
        '_request-off-update': false, // done
        '_request-off-delete': false, // done
        '_request-off-approve': false, // done
        '_overtime-create': false, // done
        '_overtime-update': false, // done
        '_overtime-delete': false, // done
        '_overtime-approve': false, // done
        '_payroll-update': false,
        '_stripe-allow-reactive-subscription': false, // done
        '_ticket-create': false, // done
        '_ticket-update': false, // done
        '_ticket-delete': false, // done
        '_widget-customer-new-thirty-days': false,  // done
        '_widget-member-by-squad': false, // done
        '_widget-member-by-team': false, // done
        '_widget-project-by-designer': false, // done
        '_widget-project-by-squad': false, // done
        '_widget-project-by-team': false, // done
        '_widget-overtime': false, // done
        '_widget-request-off': false, // done
        '_widget-request-off-calendar': false, // done
        '_widget-upcoming-off': false, // done
        '_widget-watched-customer': false, // done
        '_widget-unclaimed-ticket': false,
        '_widget-squad-by-team': false,
        '_disable-feature-search-bar':false,
        '_disable-feature-select-designer':false,
        '_disable-feature-select-team':false,
        '_disable-feature-select-squad-group':false,
        '_disable-feature-filter-workspace':false,
        '_workspace-manual-assign-smm': false
    }
}

export const initDesignerPermission = {
    ...initPermission,
    '_file-manager': true,
    '_project': true,
    '_customer': true,
    '_ticket': true,
    '_widget': true,
    'extra-action': {
        '_project-upload-designs': true,
        '_widget-project-by-designer': true,
    }
}
export const initLeaderPermission = {
    ...initPermission,
    '_customer': true,
    '_member': true,
    '_project': true,
    '_designer-team': true,
    '_squad-group': true,
    '_ticket': true,
    '_file-manager': true,
    '_widget': true,
    'extra-action': {
        '_project-create': true,
        '_project-edit': true,
        '_project-review': true,
        '_project-change-status': true,
        '_project-manual-assign-designers': true,
        '_project-verify-designs': true,
        '_project-upload-designs': true,
        '_project-mask-feature': true,
        '_project-edit-anyone-comments': true,
        '_project-delete-anyone-comments': true,
        '_member-create': true,
        '_member-update': true,
        '_member-disable': true,
        '_squad-group-create': true,
        '_squad-group-update': true,
        '_designer-team-update': true,
        '_ticket-create': true,
        '_widget-member-by-squad': true,
        '_widget-member-by-team': true,
        '_widget-project-by-squad': true,
        '_widget-project-by-team': true,
        '_widget-watched-customer': true,
        '_widget-squad-by-team': false,
    }
}
export const initSupportPermission = {
    ...initPermission,
    '_customer': true,
    '_member': true,
    '_project': true,
    '_ticket': true,
    '_file-manager': true,
    '_widget': true,
    'extra-action': {
        '_project-create': true,
        '_project-edit': true,
        '_project-review': true,
        '_project-change-status': true,
        '_project-manual-assign-designers': true,
        '_project-verify-designs': true,
        '_project-upload-designs': true,
        '_project-mask-feature': true,
        '_project-edit-anyone-comments': true,
        '_project-delete-anyone-comments': true,
        '_customer-update-account': true,
        '_ticket-create': true,
        '_ticket-update': true,
        '_widget-project-by-squad': true,
        '_widget-project-by-team': true,
        '_widget-watched-customer': true,
        '_widget-customer-new-thirty-days': false,
        '_widget-squad-by-team': false,
    }
}
export const initPmPermission = {
    ...initPermission,
    '_customer': true,
    '_member': true,
    '_project': true,
    '_ticket': true,
    '_file-manager': true,
    '_widget': true,
    'extra-action': {
        '_project-create': true,
        '_project-edit': true,
        '_project-review': true,
        '_project-change-status': true,
        '_project-manual-assign-designers': true,
        '_project-verify-designs': true,
        '_project-upload-designs': true,
        '_project-mask-feature': true,
        '_project-edit-anyone-comments': true,
        '_project-delete-anyone-comments': true,
        '_customer-update-account': true,
        '_widget-project-by-squad': true,
        '_widget-project-by-team': true,
        '_widget-watched-customer': true,
        '_widget-customer-new-thirty-days': false,
        '_widget-squad-by-team': false,
    }
}
export const initSquadLeaderPermission = {
    ...initPermission,
    '_customer': true,
    '_member': true,
    '_project': true,
    '_squad-group': true,
    '_ticket': true,
    '_file-manager': true,
    '_widget': true,
    'extra-action': {
        '_project-create': true,
        '_project-edit': true,
        '_project-review': true,
        '_project-change-status': true,
        '_project-manual-assign-designers': true,
        '_project-verify-designs': true,
        '_project-upload-designs': true,
        '_project-mask-feature': true,
        '_project-edit-anyone-comments': true,
        '_project-delete-anyone-comments': true,
        '_ticket-create': true,
        '_widget-project-by-squad': true,
        '_widget-member-by-squad': true,
        '_widget-watched-customer': true,
        '_widget-squad-by-team': false,
    }
}
export const initHRPermission = {
    ...initPermission,
    '_member': true,
    '_project': true,
    '_squad-group': true,
    '_designer-team': true,
    '_file-manager': true,
    '_checkin': true,
    '_request-off': true,
    '_overtime': true,
    '_ticket': true,
    '_widget': true,
    'extra-action': {
        '_checkin-create': true,
        '_checkin-update': true,
        '_checkin-delete': true,
        '_request-off-create': true,
        '_request-off-update': true,
        '_request-off-delete': true,
        '_request-off-approve': true,
        '_overtime-create': true,
        '_overtime-update': true,
        '_overtime-delete': true,
        '_overtime-approve': true,
        '_designer-team-create': true,
        '_designer-team-update': true,
        '_squad-group-create': true,
        '_squad-group-update': true,
        '_member-create': true,
        '_member-update': true,
        '_widget-member-by-squad': true,
        '_widget-member-by-team': true,
        '_widget-watched-customer': true,
        '_widget-overtime': true,
        '_widget-request-off': true,
        '_widget-request-off-calendar': true,
        '_widget-upcoming-off': true,
        '_widget-squad-by-team': false,
    }
}
export const initSMMPermission = {
    ...initPermission,
    '_file-manager': true,
    '_project': true,
    '_widget': true,
    '_workspace': true,
    'extra-action': {
        '_disable-feature-search-bar':true,
        '_disable-feature-select-designer':true,
        '_disable-feature-select-team':true,
        '_disable-feature-select-squad-group':true,
        '_disable-feature-filter-workspace':true,
        '_workspace-manual-assign-smm': false
    }
}
