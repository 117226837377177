import { Injectable, inject } from '@angular/core';
import { LogService } from './log.service';
import { Brand } from '../models/brand.model';
import { map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as firebase from 'firebase/firestore';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { OrderInterface } from '../interfaces/order-interface';


@Injectable({
  providedIn: 'root',
})
export class BrandService extends LogService<Brand> {
  private readonly nzMessageService = inject(NzMessageService);

  async getAllCustomerBrand(team_id: string) {
    try {
      const list = Array<Brand>();
      const snapshot = await this.afs.firestore.collection('team').doc(team_id).collection('libraries').orderBy('created_at', 'desc').get();
      snapshot.forEach((doc: any) => {
        const brand = doc.data() as Brand;
        brand.id = doc.id;
        brand.team_id = team_id;
        list.push(brand);
      });
      return list;
    } catch (error) {
      console.log(error);
      return [];
    }

  }
  addBrand(brand: Brand) {
    try {
      return this.afs.firestore.collection('team').doc(brand.team_id).collection('libraries').add({ ...brand })
        .then((rs) => {
          this.log_model.action = 'add';
          this.log_model.data = { ...new Brand, ...brand, brand_id: rs.id } as any;
          this.createLog();
          const brand_temp = { ...brand } as Brand;
          brand_temp.id = rs.id;
          this.nzMessageService.success('Brand successfully added!');
          return { flag: true, message: 'Brand successfully added!', data: brand_temp }
        })
        .catch(error => {
          this.nzMessageService.error(error.message);
          return { flag: false, message: error.message, data: {} as Brand }
        })
    } catch (err) {
      console.log(err);
      return Promise.resolve({ flag: false, message: err as string, data: {} as Brand });
    }

  }
  updateBrand(brand: Brand) {
    delete brand.list_project;
    return this.afs.firestore.collection('team').doc(brand.team_id).collection('libraries').doc(brand.id).update({ ...brand })
      .then(() => {
        this.log_model.action = 'update';
        this.log_model.data = { ...new Brand, ...brand, brand_id: brand.id } as any;
        this.createLog();
        this.nzMessageService.success('Brand successfully updated!');
        return { flag: true, message: 'Brand successfully updated!', data: brand };
      })
      .catch(error => {
        this.nzMessageService.error(error.message);
        return { flag: false, message: error.message, data: {} as Brand }
      })
  }
  deleteBrand(brand: Brand) {
    return this.afs.firestore.collection('team').doc(brand.team_id).collection('libraries').doc(brand.id).delete()
      .then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ...new Brand, ...brand, brand_id: brand.id } as any;
        this.createLog();
        this.nzMessageService.success('Brand successfully deleted!');
        return { flag: true, message: 'Brand successfully deleted!' }
      })
      .catch(error => {
        this.nzMessageService.error(error.message);
        return { flag: false, message: error.message, data: {} as Brand }
      })
  }

  getBrandbyProject(client_team_id: string, project_id: string) {
    return this.afs.collection(`team/${client_team_id}/group_project`, ref =>
      ref.where('project_id', '==', project_id)
    ).snapshotChanges().pipe(
      map(actions => actions.map(
        a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, data };
        }
      ))
    );
  }

  getBrandDetail(client_team_id: string, brand_id: string) {
    return this.afs.doc(`team/${client_team_id}/libraries/${brand_id}`).valueChanges({ idField: 'id' });
  }

  getProjectbyBrand(client_team_id: string, brand_id: string) {
    return this.afs.collection(`team/${client_team_id}/group_project`, ref =>
      ref.where('group_id', '==', brand_id)
    ).snapshotChanges().pipe(
      map(actions => actions.map(
        a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, data };
        }
      ))
    );
  }

  addProjectToBrand(client_team_id: string, project_id: string, brand_id: string, user_id: string) {
    try {
      return this.afs.collection(`team/${client_team_id}/group_project`).doc(`${brand_id}_${project_id}`).set({
        added_at: firebase.Timestamp.now(),
        group_id: brand_id,
        group_ref: this.afs.firestore.doc(`team/${client_team_id}/libraries/${brand_id}`),
        project_id: project_id,
        project_ref: this.afs.firestore.doc(`project_v2/${project_id}`),
        team_id: client_team_id,
        team_ref: this.afs.firestore.doc(`team/${client_team_id}`),
        user_id: user_id,
        user_ref: this.afs.firestore.doc(`user/${user_id}`)
      }, { merge: true }).then(rs => {
        return ({ flag: true, message: 'Project successfully added to brand' });
      }).catch(err => {
        console.log(err);
        return ({ flag: false, message: err as string });
      })
    } catch (error) {
      console.log(error);
      return ({ flag: false, message: error as string });
    }
  }

  /* BRAND V2 */
  async listBrand(
    limit: number = -1,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string,
    order_by?: Array<OrderInterface>) {
    try {
      let query: Query = this.afs.firestore.collection('brand_v2');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      if (order_by && order_by.length > 0) {
        order_by.forEach(q => {
          query = query.orderBy(q.field_name, q.field_direction);
        });
      } else {
        query = query.orderBy('created_at', 'desc');
      }

      if (start_after) {
        const doc = await this.afs.firestore.doc(`brand_v2/${start_after}`).get();
        query = query.startAfter(doc);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`brand_v2/${end_before}`).get();
        query = query.endBefore(doc);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<Brand> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Brand;
          data.id = doc.id;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [] as Brand[];
    }
  }

  addBrandV2(brand: Brand) {
    try {
      return this.afs.firestore.collection('brand_v2').add({ ...brand })
      .then((rs) => {
        this.log_model.action = 'add';
        this.log_model.data = { ...new Brand, ...brand };
        this.createLog();
        this.nzMessageService.success('Brand successfully added!');
        return ({ flag: true, message: 'Brand successfully added!', data: {...brand, id: rs.id} as Brand });
      })
      .catch(error => {
        this.nzMessageService.error(error.message);
        return ({ flag: false, message: error.message as string, data: {} as Brand });
      })
    } catch (err) {
      this.nzMessageService.error(err as string);
      return Promise.resolve({ flag: false, message: err as string, data: {} as Brand });
    }
  }

  updateBrandV2(brand: Brand) {
    try {
      delete brand.list_project;
      return this.afs.firestore.doc(`brand_v2/${brand.id}`).update({ ...brand })
      .then(() => {
        this.log_model.action = 'update';
        this.log_model.data = { ...new Brand, ...brand };
        this.createLog();
        this.nzMessageService.success('Brand successfully updated!');
        return ({ flag: true, message: 'Brand successfully updated!' });
      })
      .catch(error => {
        this.nzMessageService.error(error.message);
        return ({ flag: false, message: error.message as string });
      })
    } catch (err) {
      this.nzMessageService.error(err as string);
      return Promise.resolve({ flag: false, message: err as string });
    }
  }
}
