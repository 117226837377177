<ng-container *ngIf="file">
  <ng-container [ngSwitch]="item_view">
    <ng-container *ngSwitchCase="'short'">
      <div class="w-[80px] h-[55px] border border-gray-100 rounded relative group/itemUpload" nz-tooltip [nzTooltipTitle]="file.name">
        <ng-container [ngSwitch]="file.type">
          <ng-container *ngSwitchCase="'image'">
            <a (click)="openImage(open_image)">
              <ng-container *ngTemplateOutlet="is_image"></ng-container>
            </a>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="is_file"></ng-container>
          </ng-container>
        </ng-container>
        <div class="absolute inset-0 z-20 bg-black/30 flex items-start justify-end p-0.5 rounded opacity-0 group-hover/itemUpload:opacity-100 transition-all ease-out">
          <span *ngIf="file.status==='completed' || file.status==='failed'" class="material-icons-outlined text-red-600 cursor-pointer text-[20px] rounded bg-red-100" (click)="deleteFile(file.id)">
            delete_forever
          </span>
        </div>
        <nz-progress *ngIf="file.status!=='completed' && file.status!=='failed'" [nzPercent]="file.progress" [nzStatus]="(file.status==='pending' || file.status==='uploading')?'active':file.status==='completed'?'success':file.status==='failed'?'exception':'active'" [nzStrokeWidth]="5" class="[&_.ant-progress-text_.anticon]:text-[14px] [&_.ant-progress-text]:inline-flex [&_.ant-progress-inner]:bg-gray-300 [&_.ant-progress-text]:w-[20px] [&_.ant-progress-show-info_.ant-progress-outer]:mr-[calc(-20px-8px)] [&_.ant-progress-show-info_.ant-progress-outer]:pr-[calc(20px+8px)] [&_.ant-progress-show-info_.ant-progress-outer]:inline-flex [&_.ant-progress-line]:leading-[0] absolute -bottom-[14px] right-0 left-0 z-20" [nzShowInfo]="false"></nz-progress>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="flex items-center border-b border-gray-300 py-3.5 space-x-3 w-full file-upload-item">
        <div class="w-[80px] h-[55px] border border-gray-100 rounded overflow-hidden">
          <ng-container [ngSwitch]="file.type">
            <ng-container *ngSwitchCase="'image'">
              <a (click)="openImage(open_image)">
                <ng-container *ngTemplateOutlet="is_image"></ng-container>
              </a>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="is_file"></ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="w-[calc(100%-80px-12px-36px-12px)]">
          <p [title]="file.name" class="truncate font-semibold text-gray-700 dark:text-gray-200">{{file.name}}</p>
          <p *ngIf="file.status!=='failed'; else failed" class="text-gray-400 text-sm">File size: {{file.size | nzBytes}}</p>
          <ng-template #failed>
            <p class="text-red-600 text-sm">
              {{file.error_message}}
            </p>
          </ng-template>
          <nz-progress [nzPercent]="file.status!=='failed'?file.progress:100" [nzStatus]="(file.status==='pending' || file.status==='uploading')?'active':file.status==='completed'?'success':file.status==='failed'?'exception':'active'" [nzStrokeWidth]="5" class="[&_.ant-progress-text_.anticon]:text-[14px] [&_.ant-progress-text]:inline-flex [&_.ant-progress-text]:w-[20px] [&_.ant-progress-show-info_.ant-progress-outer]:mr-[calc(-20px-8px)] [&_.ant-progress-show-info_.ant-progress-outer]:pr-[calc(20px+8px)] [&_.ant-progress-show-info_.ant-progress-outer]:inline-flex [&_.ant-progress-line]:leading-[0] flex mt-1.5" [nzShowInfo]="false"></nz-progress>
        </div>
        <div class="w-9 h-[55px] flex flex-col justify-between text-center">
          <span (click)="deleteFile(file.id)">
            <span *ngIf="file.status==='completed' || file.status==='failed'" class="material-icons-outlined text-red-600 cursor-pointer text-[20px]">
              delete
            </span>
          </span>
          <ng-container [ngSwitch]="file.status">
            <ng-container *ngSwitchCase="'failed'">
              <span class="material-icons-outlined text-red-600 text-[16px] ml-0.5 -mb-1">
                error
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="'completed'">
              <span class="material-icons-outlined text-green-500 text-[20px] -mb-1">
                done
              </span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="text-gray-700 text-sm -mb-1.5">{{file.progress}}%</span>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #is_image>
    <img
        [src]="file.path_preview"
        [alt]="file.name"
        class="w-full h-full rounded object-cover object-center bg-gray-100"
      />
  </ng-template>
  <ng-template #open_image>
    <div class="flex justify-center items-center">
      <img
        [src]="file.path_preview"
        [alt]="file.name"
        class="w-auto h-auto rounded object-cover object-center bg-gray-100"
      />
    </div>
  </ng-template>
  <ng-template #is_file>
    <p class="w-full h-full flex items-center justify-center text-white bg-purple-600 rounded uppercase text-sm font-semibold">
      {{file.type.split('/')[1]}}
    </p>
  </ng-template>
</ng-container>


