<nz-sider
nzCollapsible
[nzCollapsed]="siderCollapsed"
[nzWidth]="siderCollapsed?80:248"
[nzTrigger]="null"
  nzTheme="light"
  [nzTrigger]="null"
  class="main-sider fixed z-50 top-[64px] bottom-[58.25px] overflow-y-auto overflow-x-hidden border-r border-gray-100 transition-all duration-300 dark:border-gray-500 dark:bg-gray-600"
>
  <ul nz-menu nzMode="inline" *ngIf="items; else noItems"
    class="
    main-sider-menu border-0
    [&.ant-menu-inline-collapsed>li]:!bg-transparent
    [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title]:!p-0
    [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title]:justify-center
    [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title>div]:p-2.5
    [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title>div]:rounded
    [&.ant-menu-inline-collapsed>.ant-menu-item-selected>.ant-menu-submenu-title>div]:bg-primary-500
    [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title_.title]:hidden
    [&.ant-menu:not(.ant-menu-horizontal)_.ant-menu-item-selected.ant-menu-submenu-selected]:!bg-transparent
    [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title>.title-template]:flex
    [&.ant-menu-inline-collapsed>li>.ant-menu-submenu-title>.title-template]:justify-center
    dark:bg-gray-600
    ">
    <ng-container *ngFor="let item of items; index as i">
      <li
        nz-submenu
        [routerLink]="item.items ? undefined : item.link"
        routerLinkActive="ant-menu-item-selected"
        [routerLinkActiveOptions]="{exact: false}"
        [nzTitle]="title"
        class="
        relative
        group
        transition-none

        [&>.ant-menu-submenu-title]:bg-transparent
        [&>.ant-menu-submenu-title]:my-0
        [&>.ant-menu-submenu-title]:py-0
        [&>.ant-menu-submenu-title]:transition-none
        [&>.ant-menu-submenu-title]:!px-0
        [&>.ant-menu-submenu-title]:!h-16
        [&>.ant-menu-submenu-title]:leading-16

        [&>.ant-menu-submenu-title>.title-template]:!h-16
        [&>.ant-menu-submenu-title>.title-template]:leading-16
        [&>.ant-menu-submenu-title>.title-template]:py-4
        [&>.ant-menu-submenu-title>.title-template]:pl-6
        [&>.ant-menu-submenu-title>.title-template]:w-full
        [&>.ant-menu-submenu-title>.title-template]:rounded
        hover:[&>.ant-menu-submenu-title>.title-template]:!bg-purple-100
        hover:!bg-purple-100
        [&>.ant-menu-submenu-title>.title-template>.icons>span:last-child]:w-[calc(100%-48px)]

        [&:not(.ant-menu-item-selected)_.icon-filled]:hidden
        [&.ant-menu-item-selected:not(.ant-menu-submenu-selected)_.icon-outlined]:hidden

        [&.ant-menu-submenu-selected.ant-menu-item-selected_.icon-filled]:hidden

        [&.ant-menu-item-selected:not(.ant-menu-submenu-selected)]:!bg-purple-100
        [&.ant-menu-item-selected:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title>div>.title]:text-purple-500
        [&.ant-menu-item-selected:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title>div>.icons>.icon]:text-purple-500
        [&.ant-menu-item-selected:not(.ant-menu-submenu-selected)_.ant-menu-submenu-title_.title]:font-bold

        [&_.ant-menu-sub_.ant-menu-item]:!rounded
        [&_.ant-menu-sub_.ant-menu-item]:!m-0
        [&_.ant-menu-sub_.ant-menu-item]:h-12
        [&_.ant-menu-sub_.ant-menu-item]:!pl-9

        [&.ant-menu-item-selected.ant-menu-submenu-selected_.ant-menu-sub_ul>.ant-menu-item-selected:after]:hidden
        [&.ant-menu-item-selected.ant-menu-submenu-selected_.ant-menu-sub_ul>.ant-menu-item-selected]:!bg-purple-100
        [&.ant-menu-item-selected.ant-menu-submenu-selected_.ant-menu-sub_ul>.ant-menu-item-selected_.icon]:text-purple-500
        [&.ant-menu-item-selected.ant-menu-submenu-selected_.ant-menu-sub_ul>.ant-menu-item-selected_.title]:font-bold
        [&.ant-menu-item-selected.ant-menu-submenu-selected_.ant-menu-sub_ul>.ant-menu-item-selected_.title]:text-purple-500

        {{ !item.items || item.items.length < 0 ? '[&_.ant-menu-submenu-arrow]:hidden ' : ''}}
        [&_.ant-menu-submenu-arrow]:w-[39px]
        [&_.ant-menu-submenu-arrow]:h-[52.5px]
        [&_.ant-menu-submenu-arrow]:absolute
        [&_.ant-menu-submenu-arrow]:top-0
        [&_.ant-menu-submenu-arrow]:right-0
        [&_.ant-menu-submenu-arrow]:z-40
        [&_.ant-menu-submenu-arrow]:flex
        [&_.ant-menu-submenu-arrow]:justify-center
        [&_.ant-menu-submenu-arrow]:items-center
        [&_.ant-menu-submenu-arrow]:transform-none

        [&_.ant-menu-submenu-arrow:before]:w-6
        [&_.ant-menu-submenu-arrow:before]:h-6
        [&_.ant-menu-submenu-arrow:before]:transform-none
        [&_.ant-menu-submenu-arrow:before]:bg-transparent
        [&_.ant-menu-submenu-arrow:before]:origin-center
        [&_.ant-menu-submenu-arrow:before]:flex
        [&_.ant-menu-submenu-arrow:before]:items-center
        [&_.ant-menu-submenu-arrow:before]:justify-center
        [&_.ant-menu-submenu-arrow:before]:font-['Material_Icons']
        [&_.ant-menu-submenu-arrow:before]:content-['\e5cf']
        [&_.ant-menu-submenu-arrow:before]:text-[20px]
        [&_.ant-menu-submenu-arrow:after]:hidden
        [&.ant-menu-item-selected_.ant-menu-submenu-arrow]:text-purple-500

        [&.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before]:rotate-[-180deg]
        [&_.ant-menu-sub.ant-menu-inline]:!bg-[#fff]
        dark:hover:!bg-gray-500 dark:hover:[&>.ant-menu-submenu-title>.title-template]:!bg-gray-500 dark:[&.ant-menu-item-selected:not(.ant-menu-submenu-selected)]:!bg-gray-500 dark:[&.ant-menu-item-selected_.ant-menu-submenu-arrow]:text-white
        dark:[&.ant-menu-item-selected:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title>div>.title]:text-white
        dark:[&.ant-menu-item-selected:not(.ant-menu-submenu-selected)>.ant-menu-submenu-title>div>.icons>.icon]:text-white
        "
      >
        <ng-container *ngIf="item.title==='Project'">
          <a class="absolute left-0 top-0 z-50 right-10 h-[52.5px]" [routerLink]="item.link"></a>
        </ng-container>

        <ng-container *ngIf="item.items && item.items.length > 0">
          <ng-container *ngFor="let child of item.items">
            <h4 class="text-[13px] text-gray-400 uppercase p-[4px_26px] leading-[1.5]">{{child.cate}}</h4>
            <ul>
              <li
                [routerLink]="groupChild.link"
                routerLinkActive="ant-menu-item-selected"
                *ngFor="let groupChild of child.data" nz-menu-item
                [nzTooltipTitle]="groupChild.title"
                nzTooltipPlacement="right"
                nz-tooltip
                class="
                  rounded
                  hover:bg-purple-100
                  [&:hover_.material-icons-outlined]:text-purple-500
                  [&:hover_.title]:text-purple-500
                  dark:hover:bg-gray-500
                  dark:[&:hover_.material-icons-outlined]:text-white
                  dark:[&:hover_.title]:text-white
                "
              >
                <div class="flex items-center space-x-3 relative z-20">
                  <span class="material-icons-outlined w-6 h-6 icon text-gray-600 dark:text-gray-200">
                    {{groupChild.icon}}
                  </span>
                  <span class="title text-gray-600 font-medium flex items-center dark:text-white">{{ groupChild.title }}
                    <span *ngIf="groupChild.tag" class="ml-3 text-blue-600 bg-blue-100 p-[.31rem_.38rem] text-[10px] rounded w-[2.3rem] h-[1rem] flex items-center justify-center">{{groupChild.tag}}</span>
                  </span>
                </div>
              </li>
            </ul>
          </ng-container>
        </ng-container>
        <ng-template #nochild></ng-template>
        <ng-template #title>
          <div class="title-template flex items-center gap-x-3 relative z-20" [routerLink]="item.items ? item.link : undefined"
          [nzTooltipTitle]="item.title"
          nzTooltipPlacement="right"
          nz-tooltip
          >
            <span class="icons flex items-center group-hover:[&>span]:!text-purple-500 dark:group-hover:[&>span]:!text-white">
              <span class="!m-0 icon-outlined material-icons-outlined icon text-gray-600 dark:text-white  dark:group-hover:!text-whitetext-[22px]">
                {{item.icon}}
              </span>
              <span class="!m-0 icon-filled material-icons-outlined  icon text-gray-600 dark:text-white dark:group-hover:!text-white text-[22px]">
                {{item.icon}}
              </span>
            </span>
            <span class="title text-gray-600 group-hover:!text-purple-500 dark:text-white dark:group-hover:!text-white font-medium">{{ item.title }}</span>
          </div>
        </ng-template>
      </li>
    </ng-container>
  </ul>

  <ng-template #noItems>Empty list</ng-template>
</nz-sider>
<div class="fixed bottom-0 z-50 pt-2 bg-white {{siderCollapsed?'w-[80px] [&_.wsp-title]:!ml-5':'w-[248px]'}} border-r border-gray-100 transition-all duration-300 dark:bg-gray-600 dark:border-gray-500">
  <penji-workspace-dropdown-layout ></penji-workspace-dropdown-layout>
</div>
