/* eslint-disable */
import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormsModule } from '@angular/forms';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Media, UploadFile } from '@penji/shared/data-access';
import { SpinnerComponent } from '@penji/shared/ui/element/spinner';
import { UploadFileStoreService } from '@penji/shared/ui/element/upload-file-form/data-access';
import { FileManagerStoreService } from '@penji/team/media/data-access';
import { InputComponent } from '@penji/team/shared/shared-ui/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'penji-file-manager',
  standalone: true,
  imports: [
    CommonModule,
    NzTagModule,
    DatePipe,
    NzSelectModule,
    FormsModule,
    NzButtonModule,
    InputComponent,
    NzEmptyModule,
    NzTypographyModule,
    NzToolTipModule,
    NzProgressModule,
    NzPipesModule,
    SpinnerComponent,
    NzCheckboxModule,
    InfiniteScrollModule,
    NzPopconfirmModule,
    NzInputModule
  ],
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FileManagerStoreService, UploadFileStoreService]
})
export class FileManagerComponent implements OnInit {
  @Input() multiple: boolean = true;
  @Input() output_modal = false;
  @Input() file_type: 'image' | 'file' | 'font' | '' = '';
  private readonly mediaStore = inject(FileManagerStoreService);
  private readonly uploadStoreSV = inject(UploadFileStoreService);
  private readonly ref = inject(NzModalRef);
  private readonly authSV = inject(AngularFireAuth);
  action$ = new BehaviorSubject<any>({});
  isLoading$ = this.mediaStore.loading$;
  data$ = this.mediaStore.data$.pipe(
    tap(list => {
      if (list.length > 0) {
        list.forEach(item => {
          this.uploadStoreSV.addFile({
            id: item.id,
            created_at: new Date(item.created_at?.toDate().getTime()!),
            name: item.name,
            type: item.type,
            size: item.size,
            status: 'completed',
            progress: 100,
            form_id: Math.random().toString(36).substr(2, 9),
            path_preview: !item.version? item.thumb : item.original+'?format=webp&size=175x125',
            media_id: item.id,
            media_ref: item.doc
          } as UploadFile)
        })
      }
    }));

  list$ = this.action$.pipe(
    switchMap(action => {
      return this.uploadStoreSV.data_by_created_at$.pipe(map(list => {
        // console.log('list: ',list);
        if (action.type)
          return list.filter(f => f.type.includes(action.type));

        return list;
      }))
    })
  );
  highlightItem$ = new BehaviorSubject<UploadFile | undefined>(undefined);
  selectedItem$ = new BehaviorSubject<UploadFile[]>([]);


  @ViewChild('file') file!: ElementRef;

  onSelectItem(item: UploadFile) {
    this.highlightItem$.next(item)
    if (this.multiple) {
      const index = this.selectedItem$.getValue().findIndex(f => f.id === item.id);
      if (index === -1)
        this.selectedItem$.getValue().push(item);
    } else {
      this.selectedItem$.next([item]);
    }
    // console.log(this.selectedItem$.value, this.highlightItem$.value)
  }
  onRemoveItem(e: any, item: UploadFile) {
    e.stopPropagation();
    this.highlightItem$.next(undefined);
    const index = this.selectedItem$.getValue().findIndex(f => f.id === item.id);
    if (index > -1)
      this.selectedItem$.getValue().splice(index, 1);
  }


  submit() {
    // console.log(this.selectedItem$.getValue());
    const list_update = this.selectedItem$.getValue();
    this.ref.destroy(list_update);
  }
  async deleteImage(highlightItem: UploadFile) {
    const media = new Media();
    media.id = highlightItem.media_id!;
    media.owner_id = highlightItem.media_ref?.parent.parent?.id!;
    this.mediaStore.removeMedia$(media);
    this.uploadStoreSV.removeFile(highlightItem.id);
    this.highlightItem$.next(undefined);
    const index = this.selectedItem$.getValue().findIndex(f => f.id === highlightItem.id);
    if (index !== -1)
      this.selectedItem$.getValue().splice(index, 1);
  }
  deleteErrorFile(highlightItem: UploadFile) {
    this.uploadStoreSV.removeFile(highlightItem.id);
  }

  ngOnInit(): void {
    if (this.file_type !== '')
      this.action$.next({ 'type': this.file_type });
    this.mediaStore.loadMedia$(this.action$.pipe(
      switchMap(action => {
        return this.authSV.authState.pipe(map(auth => {
          if (auth)
            return { ...action, user_id: auth.uid };
        }))
      })));
  }
  inputSort(type: any) {
    // console.log(type);
    this.uploadStoreSV.removeFileAll();
    if (type === 'image') {
      const temp: any = { ...this.action$.getValue() };
      temp['type'] = 'image';
      if (temp['start_after'])
        delete temp['start_after'];
      this.action$.next(temp);
    } else if (type === 'file') {
      const temp: any = { ...this.action$.getValue() };
      temp['type'] = 'file';
      if (temp['start_after'])
        delete temp['start_after'];
      this.action$.next(temp);
    } else if (type === 'font') {
      const temp: any = { ...this.action$.getValue() };
      temp['type'] = 'font';
      if (temp['start_after'])
        delete temp['start_after'];
      this.action$.next(temp);
    } else {
      const temp: any = { ...this.action$.getValue() };
      delete temp['type'];
      if (temp['start_after'])
        delete temp['start_after'];
      this.action$.next(temp);
    }
  }
  scrollDown(start_after?: string) {
    if (start_after) {
      const temp: any = { ...this.action$.getValue() };
      temp['start_after'] = start_after;
      // console.log(start_after);
      this.action$.next(temp);
    }
  }
  onFileChange(event: any) {
    const files = event.target.files;
    const form = {
      list_file: files,
      form_id: Math.random().toString(36).substr(2, 9),
    }
    if (files && files.length > 0) {
      this.uploadStoreSV.uploadFile$(form);
    }

  }
  isfileSelected(file: UploadFile): boolean {
    return this.selectedItem$.value.some(obj => obj.id === file.id);
  }
  getExtension(name: string): string {
    return name.split('.').pop()?.toLowerCase() || '';
  }
  getSubName(name: string, length: number): string {
    return name.substring(0, length + 1);
  }
}
