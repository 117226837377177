
<ng-container *ngIf="{user: user$ | async} as vm">
  <div class="flex items-center justify-between py-1.5">
    <div class="flex items-center justify-between space-x-8">
      <a [routerLink]="['./']">
        <img src="./assets/images/logo-penji.svg" class="w-[80px] dark:hidden" alt="Penji logo">
        <img src="./assets/images/logo-penji-white.svg" class="w-[80px] hidden dark:block" alt="Penji logo">
      </a>
      <penji-search></penji-search>
      <button penjiCheckSubscriptionPermission project_type="sub_design" nz-button nzType="primary" class="flex items-center space-x-1.5 font-bold !px-5">
        <span>Create</span>
        <span class="material-icons-outlined text-[20px]">add</span>
      </button>
      <!-- <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable
          class="min-w-[240px] [&_.material-icons-outlined]:text-[22px] [&_.material-icons-outlined]:text-gray-500 [&_.ant-dropdown-menu-item]:space-x-2 [&_.ant-dropdown-menu-item]:py-2.5">
          <li nz-menu-item penjiCheckSubscriptionPermission project_type="sub_design">
            <span class="material-icons-outlined">
              all_inclusive
            </span>
            <span>New design project</span>
          </li>
          <li nz-menu-item class="cursor-not-allowed">
            <span class="material-icons-outlined">
              auto_fix_high
            </span>
            <span>New AI design project <span class="ml-1 !text-blue-600 bg-blue-100 p-[.31rem_.38rem] text-[10px] rounded inline-flex w-[2.3rem] h-[1rem] items-center justify-centert">Soon</span></span>
          </li>
          <li nz-menu-divider></li>
          <li nz-menu-item class="cursor-default">
            <span class="material-icons-outlined">
              text_fields
            </span>
            <span>New content writing project <span class="ml-1 text-blue-600 bg-blue-100 p-[.31rem_.38rem] text-[10px] rounded inline-flex w-[2.3rem] h-[1rem] items-center justify-centert">Soon</span></span>
          </li>
          <li nz-menu-divider></li>
          <li nz-menu-item penjiCheckSubscriptionPermission project_type="one_design">
            <span class="material-icons-outlined !text-purple-600">
              shopping_cart
            </span>
            <span class="!text-purple-600 font-medium">Get more projects</span>
          </li>
        </ul>
      </nz-dropdown-menu> -->
    </div>

    <div class=" min-w-[50%] flex items-center justify-end space-x-8">
      <!-- <a [routerLink]="'./rewards'" class="
      flex items-center justify-between
      border border-transparent hover:border-green-300 hover:bg-green-100 pl-4 pr-3 py-0.5 rounded h-11
      group
    ">
        <img src="../assets/images/workspace_premium.svg" class="min-w-[24px] min-h-[24px] mr-2"
          alt="workspace_premium.svg">
        <span class="mr-2 font-medium group-hover:text-black text-[16px] mt-0.5">
          Rewards
        </span>
        <span
          class="font-medium text-sm text-green-600 bg-green-100 group-hover:text-green-600 group-hover:bg-green-100 p-2 rounded h-[1.375rem] flex items-center justify-center mt-0.5">
          $10.00
        </span>
      </a> -->

      <!-- <penji-membership-name></penji-membership-name> -->
      <penji-theme-dropdown class="[&_.theme-dropdown]:shadow-md hover:[&_.theme-dropdown]:shadow-lg"></penji-theme-dropdown>
      <penji-notification-layout></penji-notification-layout>

      <div nz-dropdown [nzDropdownMenu]="menuUser" nzTrigger="click" [nzPlacement]="'bottomRight'" class="relative group h-[38px] w-[40px] cursor-pointer group flex items-center justify-center bg-white dark:bg-gray-600 rounded-md shadow-md hover:shadow-lg">
        <penji-media-ref class="block w-8 h-8 rounded-full overflow-hidden" [media_ref]="vm.user?.user_info?.avatar" [media_size]="'avatar'"></penji-media-ref>
        <span class="w-4 h-4 bg-white dark:bg-gray-600 rounded-full absolute bottom-0 right-0 z-10 inline-flex items-center justify-center cursor-pointer">
          <span class="material-icons-outlined text-gray-500 dark:text-gray-300 text-[18px] group-hover:text-blue-500">
            expand_more
          </span>
        </span>
      </div>
      <nz-dropdown-menu #menuUser="nzDropdownMenu">
        <ul nz-menu nzSelectable class="rounded-lg hover:[&>li]:bg-gray-100
          py-0
          [&>li>a]:space-x-3
          [&>li]:p-0
          [&>li>a]:w-full
          [&>li:first-of-type]:rounded-[.25rem_.25rem_0_0] [&>li:last-of-type]:rounded-[0_0_.25rem_.25rem] [&>li]:border-gray-100 dark:[&>li]:border-gray-500 [&>li]:border [&>li:not(:first-of-type)]:border-t-none
          [&>li>a]:!p-3
          [&>li>a>.material-icons-outlined]:text-gray-500
          [&>li>a>.material-icons-outlined]:text-[20px]
          [&>li:not(:first-of-type)>a]:font-[400]
          [&>li:not(:first-of-type)>a]:flex
          [&>li:not(:first-of-type)>a]:items-center
          min-w-[250px]
          ">
          <!-- user -->

          <li nz-menu-item [routerLink]="[ './settings' ]">
            <a class="flex items-center space-x-3 dark:text-white">
              <penji-media-ref class="block w-8 h-8 rounded-full" [media_ref]="vm.user?.user_info?.avatar" [media_size]="'avatar'"></penji-media-ref>
              <div class="max-w-[180px]">
                <p class="flex items-center font-semibold">
                  <span class="truncate capitalize">{{vm.user?.user_info?.first_name}} {{vm.user?.user_info?.last_name}}</span>
                  <img src="../assets/images/king.svg" class="w-7 h-7 ml-2 -mt-1" alt="owner">
                </p>
                <p class="text-gray-600 text-sm">
                  {{vm.user?.user_info?.email}}
                </p>
              </div>
            </a>
          </li>

          <!-- others -->

          <li nz-menu-item>
            <a [routerLink]="[ './settings' ]">
              <span class="material-icons-outlined">settings</span>
              <span>Settings</span>
            </a>
          </li>
          <!-- <li nz-menu-item>
            <a [routerLink]="['./feedback']">
              <span class="material-icons-outlined">feedback</span>
              <span>Report and problem</span>
            </a>
          </li>
          <li nz-menu-item>
            <a [routerLink]="['./share']" class="!text-blue-600 font-medium">
              <span class="material-icons-outlined !text-blue-600">share</span>
              <span>Refer and get $10!</span>
            </a>
          </li> -->
          <li nz-menu-item>
            <a *ngIf="uid$ | async" (click)="logout()">
              <span class="material-icons-outlined">logout</span>
              <span>Sign Out</span>
            </a>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</ng-container>
