import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TeamService } from '@penji/shared/data-access';
import { RouterUtils } from '@penji/shared/utils';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckOnboardingGuard implements CanActivate {

  private readonly teamSV = inject(TeamService);
  private readonly router = inject(Router);
  
  canActivate(route: ActivatedRouteSnapshot):Observable<boolean> {
    const client_team_id = route.parent?.params[RouterUtils.Configuration.client_team_id];
    if (client_team_id) {
      return this.teamSV.getTeamDetail(client_team_id).pipe(map(team_detail => {
        if (team_detail.onboarding == false) {
          this.router.navigate([`/t/${client_team_id}/dashboard`]);
          return false;
        } 
        return true;
      }))
    } else {
      return of(false)
    }
  }
  
}
