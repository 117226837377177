import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { WorkspaceStoreService } from '@penji/client-v3/workspace/data-access';
import { WorkspaceCreateEditFormComponent } from '@penji/client-v3/workspace/ui/workspace-create-edit-form';
import { WorkspaceItemComponent } from '@penji/client-v3/workspace/ui/workspace-item';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'penji-workspace-dropdown-layout',
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    WorkspaceItemComponent,
    NzModalModule,
    WorkspaceCreateEditFormComponent
  ],
  templateUrl: './workspace-dropdown-layout.component.html',
  styleUrls: ['./workspace-dropdown-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NzModalService]
})
export class WorkspaceDropdownLayoutComponent {

  private readonly workspaceStore = inject(WorkspaceStoreService);
  private readonly customerTeamStore = inject(CustomerTeamStoreService);
  private readonly modal = inject(NzModalService);
  data$ = this.workspaceStore.data$;
  team_data$ = this.customerTeamStore.team_data$;


  addWorkspace() {
    this.modal.create({
      nzCentered: true,
      nzTitle: 'Add a new Workspace',
      nzContent: WorkspaceCreateEditFormComponent,
      nzBodyStyle: { padding: '0', overflow: 'auto', background: '#fff'},
      nzWidth: '550px',
      nzStyle: { top: '16px', 'text-align': 'center' },
      nzFooter: null,
      nzKeyboard: false
    });
  }
  editWorkspace(workspace?: any) {
    // console.log(workspace);
    this.modal.create({
      nzCentered: true,
      nzTitle: 'Edit Workspace',
      nzContent: WorkspaceCreateEditFormComponent,
      nzBodyStyle: { padding: '24px', overflow: 'auto', background: '#fff'},
      nzWidth: '700px',
      nzStyle: { top: '1rem'},
      nzFooter: null,
      nzKeyboard: false,
      nzData: {
        id: workspace.id, team_id: workspace.team_id, title: workspace.title, logo: workspace.logo
      },
    });
  }
}
