<div *ngIf="notification && notification.project_id" class="flex w-full cursor-pointer px-4 py-3 gap-x-3 text-gray-600 text-[15px] {{onpage?'rounded-lg mb-5 bg-white max-w-[800px] border border-gray-200 hover:bg-blue-100 dark:hover:bg-gray-500 dark:bg-gray-600 dark:border-gray-500':'hover:bg-gray-100 max-w-[400px] dark:bg-gray-600 dark:hover:bg-gray-500'}} dark:text-white" [routerLink]="['/t/'+notification.team_id+'/project-detail/'+ notification.id]">
    <div>
        <penji-user-item [user_id]="notification.sender_id" [user_avatar]="true"
            [avatar_size]="'xl'"></penji-user-item>
    </div>
    <div class="w-[calc(100%-44px)]">
        <p class="flex flex-wrap gap-x-1"><penji-user-item [user_name]="true"
                [user_id]="notification.sender_id"></penji-user-item>{{notification.title}}.</p>
        <p class="dark:text-blue-500"><penji-project-link-title [project_id]="notification.project_id"></penji-project-link-title></p>
        <div class="w-full">
            <ng-container *ngIf="onpage; else dropdown">
                <div class="[&_p]:mb-1 dark:text-gray-300 dark:[&_p]:text-white [&_p]:text-gray-500"
                [innerHTML]="notification.content | nzSanitizer: 'html'">
            </div>
            </ng-container>
            <ng-template #dropdown>
                <div class="[&_p]:mb-1 dark:text-gray-300 {{notification.content?.length==0?'':'h-[26px]'}} w-full overflow-hidden [&_p]:text-gray-500 [&_p]:max-w-[260px] [&_p]:truncate dark:[&_p]:text-white"
                [innerHTML]="notification.content | nzSanitizer: 'html'">
            </div>
            </ng-template>
        </div>
        <p class="{{onpage?'mt-2':'mt-1 text-sm'}} text-right text-gray-500 dark:!text-gray-200">
            {{notification.created_at.toDate() | amTimeAgo}}
        </p>
    </div>
</div>