/* eslint-disable */
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, forwardRef, inject } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFile } from '@penji/shared/data-access';
import { UploadFileStoreService } from '@penji/shared/ui/element/upload-file-form/data-access';
import { FileUploadItemComponent } from '@penji/shared/ui/element/upload-file-form/ui/file-upload-item';
import { FileManagerComponent } from '@penji/team/media/media-ui/file-manager';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Observable, map, share, take, tap } from 'rxjs';

type FileExt = 'png' | 'jpg' | 'jpeg' | 'zip' | 'doc' | 'docx' | 'xls' | 'xlsx' | 'ppt' | 'pptx' | 'pdf' | 'txt' | 'rtf' | 'odt' | 'ai' | 'psd' | 'rar' | 'eps' | 'woff' | 'woff2' | 'otf' | 'ttf' | 'iso' | 'gif' | 'svg';

@Component({
  selector: 'penji-input-file-form',
  standalone: true,
  imports: [
    CommonModule,
    NzPipesModule,
    FileUploadItemComponent,
    NzCollapseModule,
    NzButtonModule,
    FormsModule,
    NzToolTipModule,
    NzAlertModule,
    DragDropModule,
    NzDropDownModule
  ],
  templateUrl: './input-file-form.component.html',
  styleUrls: ['./input-file-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputFileFormComponent),
      multi: true
    },
    NzModalModule
  ]
})
export class InputFileFormComponent implements ControlValueAccessor, OnInit {

  @ViewChild('file') file!: ElementRef;

  @Input() form_id_input: string = '';
  @Input() file_ext: Array<FileExt> = ['png', 'jpg', 'jpeg'];
  @Input() max_file_upload: number = 0;
  @Input() size_limit = 524288000;
  @Input() type_upload: 'image' | 'source' | 'font' | 'default' | 'button' | 'icon' = 'default';
  @Input() item_view: 'long' | 'short' = 'long';
  @Input() size_upload: 'small' | 'default' | 'large' = 'default';

  public readonly control = new FormControl<any>(null);


  private readonly uploadStoreSV = inject(UploadFileStoreService);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly modal = inject(NzModalService);

  onChange: (value: any) => void = () => { };
  onTouch: () => void = () => {
    console.log('test...')
  };
  pristine: boolean = true;

  form_id = '';
  upload_progress$!: Observable<UploadFile[]>;
  upload_progress_completed$!: Observable<UploadFile[]>;
  files: UploadFile[] = [];
  check_drag_drop = false;
  isSortByName = false;
  public basicInfoForm: FormGroup = new FormGroup({
    logo: new FormControl(''),
  });



  ngOnInit(): void {
    if (this.form_id_input === '') {
      this.form_id = Math.random().toString(36).substr(2, 9);
    } else {
      this.form_id = this.form_id_input;
    }
    this.upload_progress$ = this.uploadStoreSV.selectFileByFormId(this.form_id).pipe(tap(rs => {
      // console.log(rs)
      // this.files = rs.filter(f => f.status !== 'failed');
      this.files =rs;
      this.onChange(this.files);
      this.writeValue(this.files);
    }, share()));
    this.upload_progress_completed$ = this.upload_progress$.pipe(map(rs => {
      return rs.filter(f => f.status === 'completed');
    }))


  }
  uploadFile(files: any) {
    // console.log(Array.from(files).length, this.files.length)
    // console.log(this.files.length + Array.from(files).length, this.max_file_upload)
    if (this.max_file_upload != 0 && this.max_file_upload < this.files.length + Array.from(files).length) {
      this.file.nativeElement.value = '';
      return;
    }

    const { validFiles, invalidFiles } = Array.from(files as FileList).reduce(
      (result: any, file) => {
        let targetArray = file.size > this.size_limit || !this.isFileExtensionAllowed(file.name) ? 'invalidFiles' : 'validFiles';
        result[targetArray].push(file);
        return result;
      },
      { validFiles: [], invalidFiles: [] }
    );

    if (invalidFiles.length > 0) {
      Array.from(invalidFiles as FileList).forEach((file: File) => {
        const item: UploadFile = {
          id: Math.random().toString(36).substr(2, 9), // Use a proper ID generation method
          created_at: new Date(),
          name: file.name,
          size: file.size,
          type: file.type,
          status: 'failed',
          form_id: this.form_id,
          progress: 0,
          path_preview: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)),
          error_message: "Invalid file size or extension. Please choose a different file.",
        };
        this.uploadStoreSV.addFile(item); // add file into store
      }
      )
    }

    const form = {
      list_file: validFiles,
      form_id: this.form_id,
    }
    if (files && files.length > 0) {
      this.uploadStoreSV.uploadFile$(form);
    }
    this.file.nativeElement.value = '';
  }
  onFileChange(event: any): void {
    // console.log('test')
    this.onTouch();
    const files = event.target.files;
    this.uploadFile(files);
  }

  writeValue(files: any[]): void {
    this.files = files;
    // console.log(this.files);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // if (control.value) {
    //   // console.log(control.value.filter((f: any) => f.status === 'pending').length)
    //   if(control.value.filter((f: UploadFile) => (f.status === 'failed') ).length) {
    //     return control.value.filter((f: UploadFile) => (f.status === 'failed') ).length > 0 ? { uploadsFail: true } : null
    //   }
    //   return control.value.filter((f: UploadFile) => (f.status === 'pending' || f.status === 'uploading') ).length > 0 ? { uploadsIncomplete: true } : null
    // }
    // return null;

    const files = control.value as UploadFile[];

    if (files && files.some(f => f.status === 'failed')) {
      return { uploadsFail: true };
    }

    if (files && (files.length > this.max_file_upload) && this.max_file_upload !== 0) {
      return { limitFileUpload: true };
    }

    if (files && files.some(f => f.status === 'pending' || f.status === 'uploading')) {
      return { uploadsIncomplete: true };
    }
    if (files && files.some(f => f.size > this.size_limit || !this.isFileExtensionAllowed(f.name))) {
      return { invalidFiles: true }
    }

    return null;
  }


  allowDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.check_drag_drop = true
  }
  dragleave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.check_drag_drop = false;
  }
  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files) {
      const files = event.dataTransfer.files as FileList;
      this.uploadFile(files)
      // const form = {
      //   list_file: files,
      //   form_id: this.form_id,
      // }
      // if (files && files.length > 0) {
      //   this.uploadStoreSV.uploadFile$(form);
      // }
    }
    this.check_drag_drop = false;
  }

  isFileExtensionAllowed(fileName: string): boolean {
    // Get the file extension (assuming the extension is everything after the last dot)
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    // Check if the file extension is in the allowed list
    return this.file_ext.includes(fileExtension.toLowerCase() as FileExt);
  }
  drop(event: CdkDragDrop<UploadFile[]>, list_files: UploadFile[]) {

    moveItemInArray(list_files, event.previousIndex, event.currentIndex);
    list_files.map((item, i) => {
      item.index = i;
      return item;
    })
    console.log(list_files)
    this.uploadStoreSV.updateFiles(list_files);
  }
  async OpenMediaFromLibrary() {
    this.onTouch();
    const ref = this.modal.create({
      nzContent: FileManagerComponent,
      nzBodyStyle: { padding: '0', overflow: 'auto', background: '#fff', height: '94dvh' },
      nzWidth: '1200px',
      nzStyle: { top: '1rem' },
      nzComponentParams: {
        'output_modal': true,
        'multiple': this.form_id.includes('_zip') || this.max_file_upload === 1 ? false : true,
        'file_type': this.form_id.includes('_zip') ? 'file' : this.form_id.includes('_img') ? 'image' : this.form_id.includes('_font') ? 'font' : ''
      },
      nzFooter: null,
    })
    const result: UploadFile[] = await ref.afterClose.pipe(take(1)).toPromise();
    if (result) {

      result.map(item => {
        item.form_id = this.form_id;
        return item;
      })
      const { validFiles, invalidFiles } = result.reduce(
        (result: any, file) => {
          let targetArray = file.size > this.size_limit || !this.isFileExtensionAllowed(file.name) ? 'invalidFiles' : 'validFiles';
          result[targetArray].push(file);
          return result;
        },
        { validFiles: [], invalidFiles: [] }
      );

      if (invalidFiles.length > 0) {
        invalidFiles.forEach((file: UploadFile) => {
          result.map(item => {
            if (item.id === file.id) {
              item.status = 'failed';
              item.error_message = 'Invalid file size or extension. Please choose a different file.';
            }
            return item;
          })
        }
        )
      }
      // console.log(result);
      this.uploadStoreSV.addFiles(result.reverse());
    }
  }

  inputSort() {
    this.isSortByName = !this.isSortByName;
    this.uploadStoreSV.sortByName(this.form_id, this.isSortByName).then();
  }
}
