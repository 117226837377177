import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, TemplateRef } from '@angular/core';
import { UploadFile } from '@penji/shared/data-access';
import { UploadFileStoreService } from '@penji/shared/ui/element/upload-file-form/data-access';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';


@Component({
  selector: 'penji-file-upload-item',
  standalone: true,
  imports: [
    CommonModule,
    NzPipesModule,
    NzProgressModule,
    NzToolTipModule
  ],
  templateUrl: './file-upload-item.component.html',
  styleUrls: ['./file-upload-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadItemComponent {

  @Input() file?: UploadFile;
  @Input() item_view = 'long';

  private readonly modal = inject(NzModalService);

  private readonly uploadFileStoreSV = inject(UploadFileStoreService);

  async deleteFile(id:string){
    if(id){
      this.uploadFileStoreSV.removeFile(id);
    }else {
      this.uploadFileStoreSV.removeFile(id);
    }
  }
  openImage(tplContent: TemplateRef<any>): void {
    this.modal.create({
      nzContent: tplContent,
      nzBodyStyle: { padding: '1rem', overflow: 'auto', background: '#fff',},
      nzWidth: '1200px',
      nzStyle: { top: '1rem' },
      nzFooter: null
    });
  }
}
