/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Brand, BrandService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, map, mergeMap, of, switchMap, take, tap } from 'rxjs';

interface BrandState extends EntityState<Brand> {
  loading: boolean,
  error: string,
}
const adapter = createEntityAdapter<any>();
const initialState: BrandState = adapter.getInitialState({
  loading: false,
  error: '',
})

@Injectable({ providedIn: 'root' })
export class BrandStoreService extends ComponentStore<BrandState> {

  brandSV = inject(BrandService);

  constructor() { super(initialState); }

  readonly data$ = this.select(s => {
    const list = Object.values(s.entities) as Brand[];
    return list.sort((a: any, b: any) => (a.created_at < b.created_at) ? 1 : -1);
  });
  readonly loading$ = this.select(s => s.loading);
  readonly error$ = this.select((s) => s.error);

  loadAllBrand$ = this.effect((client_team_id$: Observable<string>) => {
    return client_team_id$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
        this.setState(s => adapter.removeAll(s));
      }),
      switchMap((client_team_id: string) => {
        return combineLatest(this.brandSV.getAllCustomerBrand(client_team_id), of(client_team_id));
      }),
      map(([brands, client_team_id]) => {
        if (brands && brands.length > 0) {
          const promises: any = [];
          brands.forEach(rs => {
            promises.push(this.brandSV.getProjectbyBrand(client_team_id, rs.id!).pipe(take(1)).toPromise());
          });
          Promise.all(promises).then(list_group => {
            list_group.forEach((lg, index) => {
              const list_project: string[] = [];
              if (lg.length > 0) {
                lg.forEach((g: any) => {
                  list_project.push(g.data.project_id);
                })
              }
              brands[index].list_project = list_project;
            });
            this.setState(s => adapter.setAll(brands, s));
            this.patchState({ loading: false, error: '' });
          }).catch(err => {
            this.setState(s => adapter.setAll(brands, s));
            this.patchState({ loading: false, error: '' });
          })
        } else {
          this.patchState({ loading: false, error: '' });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        throw new Error(err);
      })
    )
  });

  getBrandById(brand_id: string) {
    return this.select((s) => s.entities[brand_id] as Brand);
  }

  sortBrands(field: string, order_by: string) {
    return this.select(s => Object.values(s.entities).sort((a: any, b: any) =>(order_by==='desc' ? (a[field] > b[field]) : (a[field] < b[field]))? 1 : -1) as Brand[]);
  }

  createBrand$ = this.effect((brand$: Observable<Brand>) => {
    return brand$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((brand: Brand) => {
        return this.brandSV.addBrand(brand);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({ loading: false });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateBrand$ = this.effect((brand$: Observable<Brand>) => {
    return brand$.pipe(
      tap(() => this.patchState({ error: '' })),
      mergeMap((brand: Brand) => {
        return this.brandSV.updateBrand(brand);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({ id: rs.data.id!, changes: rs.data }, state));
        } else {
          this.patchState({ error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ error: err as string });
        return EMPTY;
      })
    )
  });

  deleteBrand$ = this.effect((brand$: Observable<Brand>) => {
    return brand$.pipe(
      tap(() => this.patchState({ error: '' })),
      mergeMap((brand: Brand) => {
        return combineLatest(this.brandSV.deleteBrand(brand), of(brand))
      }),
      map(([rs, brand]) => {
        if (rs.flag) {
          this.setState((state) => adapter.removeOne(brand.id!, state));
        } else {
          this.patchState({ error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ error: err as string });
        return EMPTY;
      })
    )
  });
}
