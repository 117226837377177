import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  titleSV = inject(Title);
  title$ = new BehaviorSubject<string>('Dashboard');

  setTitle(title: string) {
    this.titleSV.setTitle(`Penji V7.2.3 - ${title}`);
  }

}
