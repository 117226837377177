import { DatePipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { startAfter, where } from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, of } from 'rxjs';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Discussion } from '../models/discussion.model';
import { Ticket, TicketCategory, TicketDiscussion, TicketSubCategory } from '../models/ticket.model';
import { DiscussionService } from './discussion.service';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root'
})
export class TicketService extends LogService<Ticket | TicketDiscussion>{
    datePipe = inject(DatePipe);
    discussionSV = inject(DiscussionService);
    private readonly nzMessageService = inject(NzMessageService);

    async listTicket(
        limit: number = 20,
        where_query?: Array<WhereQueryInterface>,
        start_after?: string,
        end_before?: string,
        sort: 'asc' | 'desc' = 'desc') {

        try {
            let query: Query = this.afs.firestore.collection('ticket_v2');
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }
            query = query.orderBy('created_at', sort);

            if (start_after) {
                const doc = await this.afs.firestore.doc(`ticket_v2/${start_after}`).get();
                query = query.startAfter(doc).limit(limit);
            } else if (end_before) {
                const doc = await this.afs.firestore.doc(`ticket_v2/${end_before}`).get();
                query = query.endBefore(doc).limitToLast(limit);
            } else {
                // query = query.limit(limit);
                if (limit != -1) query = query.limit(limit);
            }

            return query.get().then(querySnapshot => {
                const list: Array<Ticket> = [];
                querySnapshot.forEach((doc: any) => {
                    const data = doc.data() as Ticket;
                    data.id = doc.id;
                    data.doc = doc;
                    list.push(data);
                });
                return list;
            }).catch(error => {
                console.log(error);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }
    getListTicketRealTime(
        where_query?: Array<WhereQueryInterface>,
        sort: 'asc' | 'desc' = 'desc') {
        try {
            let query: Query = this.afs.firestore.collection('ticket_v2');
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }
            query = query.orderBy('created_at', sort);
            return new Observable<Ticket[]>(observ => {
                return query.onSnapshot(
                    querySnapshot => {
                        const list: Array<Ticket> = [];

                        querySnapshot.forEach((doc) => {
                            const data = doc.data() as Ticket;
                            data.id = doc.id;
                            data.doc = doc;
                            list.push(data);
                        });
                        return observ.next(list);
                    },
                    error => {
                        observ.error(error);
                    })
            });
        } catch (error) {
            console.log(error);
            return of([]);
        }
    }
    async listTicketAdvandce(
        limit: number = 20,
        where_query_and?: Array<WhereQueryInterface>,
        where_query_or?: Array<WhereQueryInterface>,
        start_after?: string,
        end_before?: string,
        sort: 'asc' | 'desc' = 'desc') {

        try {
            const constraints_and: firebase.QueryFieldFilterConstraint[] = [];
            const constraints_or: firebase.QueryFieldFilterConstraint[] = [];
            if (where_query_and && where_query_and.length > 0) {
                where_query_and.forEach(q => {
                    constraints_and.push(where(q.field_name, q.field_operator, q.field_value));
                })
            }
            if (where_query_or && where_query_or.length > 0) {
                where_query_or.forEach(q => {
                    constraints_or.push(where(q.field_name, q.field_operator, q.field_value));
                })
            }
            let query = firebase.query(firebase.collection(this.afs.firestore,'ticket_v2'),
                firebase.and(
                    ...constraints_and,
                    firebase.or(...constraints_or)
                ),
                firebase.orderBy('created_at', sort)
            );
            if (start_after) {
                const doc = await this.afs.firestore.doc(`ticket_v2/${start_after}`).get();
                query = firebase.query(query, startAfter(doc));
                if (limit != -1) query = firebase.query(query, firebase.limit(limit));
            } else if (end_before) {
                const doc = await this.afs.firestore.doc(`ticket_v2/${end_before}`).get();
                query = firebase.query(query, firebase.endBefore(doc));
                if (limit != -1) query = firebase.query(query, firebase.limitToLast(limit));
            } else {
                if (limit != -1) query = firebase.query(query, firebase.limit(limit));
            }
            return firebase.getDocs(query).then(querySnapshot => {
                const list = Array<Ticket>();
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as Ticket;
                    data.id = doc.id;
                    data.doc = doc;
                    data.action = 'added';
                    list.push(data);
                });
                // console.log(list);
                return list;
            }).catch(err=>{
                console.log(err);
                return [];
            });

        } catch (error) {
            console.log(error);
            return [];
        }
    }
    getListTicketRealTimeAdvandce(
        where_query_and?: Array<WhereQueryInterface>,
        where_query_or?: Array<WhereQueryInterface>,
        sort: 'asc' | 'desc' = 'desc') {
        try {
            const constraints_and: firebase.QueryFieldFilterConstraint[] = [];
            const constraints_or: firebase.QueryFieldFilterConstraint[] = [];
            if (where_query_and && where_query_and.length > 0) {
                where_query_and.forEach(q => {
                    constraints_and.push(where(q.field_name, q.field_operator, q.field_value));
                })
            }
            if (where_query_or && where_query_or.length > 0) {
                where_query_or.forEach(q => {
                    constraints_or.push(where(q.field_name, q.field_operator, q.field_value));
                })
            }
            const query = firebase.query(firebase.collection(this.afs.firestore,'ticket_v2'),
                firebase.and(
                    ...constraints_and,
                    firebase.or(...constraints_or)
                ),
                firebase.orderBy('created_at', sort)
            );
            return new Observable<Ticket[]>(observ => {
                return firebase.onSnapshot(query,
                        querySnapshot => {
                            const list: Array<Ticket> = [];
                            querySnapshot.docChanges().forEach((change) => {
                                const data = change.doc.data() as Ticket;
                                data.id = change.doc.id;
                                data.action = change.type;
                                list.push(data);
                            });
                            return observ.next(list);
                        },
                        error => {
                            observ.error(error);
                        })
                });
        } catch (error) {
            console.log(error);
            return of([]);
        }
    }

    getTicketDetail(ticket_id: string) {
        return this.afs.doc<Ticket>(`ticket_v2/${ticket_id}`).valueChanges({ idField: 'id' });
    }

    addTicket(ticket: Ticket) {
        return this.afs.collection(`ticket_v2`).add({ ...ticket }).then(rs => {
            this.log_model.action = 'create';
            this.log_model.data = { ... new Ticket, ...ticket, ticket_id: rs.id };
            this.createLog();
            // add project discussion if from report issues
            if (ticket.ticket_main_category_id == '2xofHRT1A01Ds3KVc39k' && ticket.project && ticket.project.length > 0) {
                const discussion = new Discussion();
                discussion.project_id = ticket.project[0]['project_id'];
                discussion.client_team_id = ticket.project[0]['client_team_id'];
                discussion.created_at = firebase.Timestamp.now();
                discussion.type = 'text';
                discussion.user_id = ticket.owner_id;
                discussion.user_ref = this.afs.firestore.doc(`user/${ticket.owner_id}`);
                discussion.owner_id = ticket.owner_id;
                discussion.owner_ref = this.afs.firestore.doc(`user/${ticket.owner_id}`);
                discussion.hidden = true;
                discussion.remove = false;
                discussion.content = 'Reported a ticket';
                discussion.ticket_id = rs.id;
                this.discussionSV.addDiscussion(
                    ticket.project[0]['client_team_id'],
                    ticket.project[0]['project_id'],
                    discussion
                );
            }// end
            this.nzMessageService.success('Ticket successfully added!');
            return ({ flag: true, message: 'Ticket successfully added!', data: { ...ticket, id: rs.id } });
        }).catch((err: any) => {
            this.nzMessageService.error(err.message);
            return ({ flag: false, message: err.message, data: {} as Ticket });
        });
    }

    updateTicket(ticket: Ticket) {
        if (ticket.doc) delete ticket.doc;
        return this.afs.doc(`ticket_v2/${ticket.id}`).update({ ...ticket }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ... new Ticket, ...ticket, ticket_id: ticket.id };
            this.createLog();
            this.nzMessageService.success('Ticket successfully updated!');
            return ({ flag: true, message: 'Ticket successfully updated!' });
        }).catch((err: any) => {
            this.nzMessageService.error(err.message);
            return ({ flag: false, message: err.message });
        });
    }

    updateViewerTicket(ticket: Ticket, uid: string) {
        return this.afs.doc(`ticket_v2/${ticket.id}`).update({
            "viewer": firebase.arrayUnion(uid)
        }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ... new Ticket, ...ticket, ticket_id: ticket.id };
            this.createLog();
            this.nzMessageService.success('Ticket successfully updated!');
            return ({ flag: true, message: 'Ticket successfully updated!' });
        }).catch((err: any) => {
            this.nzMessageService.error(err.message);
            return ({ flag: false, message: err.message });
        });
    }


    deleteTicket(ticket_id: string) {
        return this.afs.doc(`ticket_v2/${ticket_id}`).delete().then(() => {
            this.log_model.action = 'delete';
            this.log_model.data = { ... new Ticket, ticket_id: ticket_id } as Ticket;
            this.createLog();
            this.nzMessageService.success('Ticket successfully deleted!');
            return ({ flag: true, message: 'Ticket successfully deleted!' });
        }).catch((err: any) => {
            this.nzMessageService.error(err.message);
            return ({ flag: false, message: err.message });
        });
    }

    listTicketCategoryV2() {
        try {
            return this.afs.firestore.collection('ticket_category_v2')
                .get().then(querySnapshot => {
                    const list = Array<TicketCategory>();
                    querySnapshot.forEach((doc: any) => {
                        const data = doc.data() as TicketCategory;
                        data.id = doc.id;
                        list.push(data);
                    });
                    return list;
                });
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    listTicketSubCategoryV2(ticket_category_id: string) {
        try {
            return this.afs.firestore.collection(`ticket_category_v2/${ticket_category_id}/ticket_sub_category`)
                .get().then(querySnapshot => {
                    const list = Array<TicketSubCategory>();
                    querySnapshot.forEach((doc: any) => {
                        const data = doc.data() as TicketSubCategory;
                        data.id = doc.id;
                        list.push(data);
                    });
                    return list;
                });
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    listTicketDiscussion(
        limit: number = 20,
        ticket_id?: string,
        where_query?: Array<WhereQueryInterface>,
        start_after?: QueryDocumentSnapshot<DocumentData>,
        end_before?: QueryDocumentSnapshot<DocumentData>,
        start_at?: QueryDocumentSnapshot<DocumentData>,
        end_at?: QueryDocumentSnapshot<DocumentData>) {
        try {
            let query: Query = this.afs.firestore.collection(`ticket_v2/${ticket_id}/discussion`);
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }
            query = query.orderBy('created_at', 'desc');
            if (start_after) {
                query = query.startAfter(start_after);
                if (limit != -1) query = query.limit(limit);
            } else if (end_before) {
                query = query.endBefore(end_before);
                if (limit != -1) query = query.limitToLast(limit);
            } else if (start_at) {
                query = query.startAt(start_at);
                if (limit != -1) query = query.limit(limit);
            } else if (end_at) {
                query = query.endAt(end_at);
                if (limit != -1) query = query.limitToLast(limit);
            } else {
                if (limit != -1) query = query.limit(limit);
            }

            return query.get().then(querySnapshot => {
                const list: Array<TicketDiscussion> = [];
                querySnapshot.forEach((doc: any) => {
                    // console.log(doc);
                    const data = doc.data() as TicketDiscussion;
                    data.id = doc.id;
                    data.doc = doc;
                    data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMM d, y');
                    data['realtime'] = false;
                    data['payload_type'] = 'added';
                    list.push(data);
                });
                return list;
            }).catch(error => {
                console.log(error);
                return [];
            }

            );
        } catch (error) {
            return Promise.resolve([]);
        }
    }

    listTicketDiscussionRealtime(
        ticket_id?: string,
        where_query?: Array<WhereQueryInterface>,
        end_before?: QueryDocumentSnapshot<DocumentData> | null) {
        try {
            let query: Query = this.afs.firestore.collection(`ticket_v2/${ticket_id}/discussion`);
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }
            query = query.orderBy('created_at', 'desc');

            if (end_before) {
                query = query.endBefore(end_before);
            }
            return new Observable<TicketDiscussion[]>(observ => {
                return query.onSnapshot(querySnapshot => {
                    const list: Array<TicketDiscussion> = [];
                    querySnapshot.docChanges().forEach((change: any) => {
                        const data = change.doc.data() as TicketDiscussion;
                        data.id = change.doc.id;
                        data.doc = change.doc;
                        data['realtime'] = true;
                        data['payload_type'] = change.type;
                        data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMM d, y');
                        list.push(data);
                    });
                    return observ.next(list);
                });
            });
        } catch (error) {
            console.log(error);
            return of([]);
        }
    }

    addTicketDiscussion(discussion: TicketDiscussion) {
        return this.afs.collection(`ticket_v2/${discussion.ticket_id}/discussion`).add({ ...discussion }).then(rs => {
            this.log_model.action = 'create';
            this.log_model.data = { ...new TicketDiscussion, ...discussion };
            this.createLog();
            this.nzMessageService.success('Ticket discussion successfully added!');
            return ({ flag: true, message: 'Ticket discussion successfully added!', data: { ...discussion, id: rs.id } });
        }).catch((err: any) => {
            this.nzMessageService.error(err.message);
            return ({ flag: false, message: err.message, data: {} as TicketDiscussion });
        });
    }

    updateTicketDiscussion(discussion: TicketDiscussion) {
        return this.afs.doc(`ticket_v2/${discussion.ticket_id}/discussion/${discussion.id}`).update({ ...discussion }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ...new TicketDiscussion, ...discussion };
            this.createLog();
            this.nzMessageService.success('Ticket discussion successfully updated!');
            return ({ flag: true, message: 'Ticket discussion successfully updated!' });
        }).catch((err: any) => {
            this.nzMessageService.error(err.message);
            return ({ flag: false, message: err.message });
        });
    }

    deleteTicketDiscussion(discussion: TicketDiscussion) {
        return this.afs.doc(`ticket_v2/${discussion.ticket_id}/discussion/${discussion.id}`).delete().then(() => {
            this.log_model.action = 'delete';
            this.log_model.data = { ... new TicketDiscussion, id: discussion.id } as TicketDiscussion;
            this.createLog();
            this.nzMessageService.success('Ticket discussion successfully deleted!');
            return ({ flag: true, message: 'Ticket discussion successfully deleted!' });
        }).catch((err: any) => {
            this.nzMessageService.error(err.message);
            return ({ flag: false, message: err.message });
        });
    }
}
