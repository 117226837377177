/* eslint-disable */
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';
import { MediaService, UploadFile } from '@penji/shared/data-access';
import PromisePool from '@supercharge/promise-pool';
import { EMPTY, Observable, catchError, map, mergeMap, of, switchMap, take } from 'rxjs';
import * as mime from 'mime';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireAuth } from '@angular/fire/compat/auth';
export interface UploadFileState extends EntityState<UploadFile> {
    loading: boolean;
}
export const adapter_file = createEntityAdapter<UploadFile>();
export const initialState_file: UploadFileState = adapter_file.getInitialState({
    loading: false,
})

@Injectable()
export class UploadFileStoreService extends ComponentStore<UploadFileState> {
    private readonly sanitizer = inject(DomSanitizer);
    private readonly mediaSV = inject(MediaService);
    private readonly afa = inject(AngularFireAuth);
    readonly data$ = this.select(s => {
        const list = Object.values(s.entities) as UploadFile[];
        return list.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
    });
    readonly data_by_created_at$ = this.select(s => {
        const list = Object.values(s.entities) as UploadFile[];
        return list.sort((a: any, b: any) => (a.created_at > b.created_at) ? -1 : 1);
    });
    readonly loading$ = this.select(s => s.loading);

    readonly addFile = this.updater((state: UploadFileState, item: UploadFile) =>
        adapter_file.addOne(item, state)
    );
    readonly addFiles = this.updater((state: UploadFileState, items: UploadFile[]) =>
        adapter_file.addMany(items, state)
    );

    readonly updateFile = this.updater((state: UploadFileState, update: Update<UploadFile>) =>
        adapter_file.updateOne(update, state)
    );
    readonly updateFiles = this.updater((state: UploadFileState, list: UploadFile[]) =>
        adapter_file.upsertMany(list, state)
    );

    readonly removeFile = this.updater((state: UploadFileState, id: string) =>
        adapter_file.removeOne(id, state)
    );
    readonly removeFileAll = this.updater((state: UploadFileState) =>
        adapter_file.removeAll(state)
    );
    readonly selectFileById = (id: string): Observable<UploadFile> =>
        this.select(state => state.entities[id] as UploadFile);

    readonly selectFileByFormId = (form_id: string) =>
        this.select(s => {
            const list = Object.values(s.entities) as UploadFile[];
            return list.filter(f => f.form_id === form_id).sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
        });
    readonly selectFileIncludeByFormId = (form_id: string) =>
        this.select(s => {
            const list = Object.values(s.entities) as UploadFile[];
            return list.filter(f => f.form_id.includes(form_id)).sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
        });
    constructor() {
        super(initialState_file);
    }

    uploadFile$ = this.effect((file$: Observable<any>) => {
        return file$.pipe(
            switchMap(files => {
                return this.afa.authState.pipe(map(auth => {
                    if (auth) {
                        return { uid: auth.uid, files: files };
                    }
                    return { uid: '', files: files };
                }))
            }),
            map((result) => {
                // console.log(result.files)
                if (result.uid !== '') {
                    Array.from(result.files.list_file as FileList).forEach(
                        async (file, index) => {
                            const item: UploadFile = {
                                id: Math.random().toString(36).substr(2, 9), // Use a proper ID generation method
                                created_at: new Date(),
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                status: 'uploading',
                                form_id: result.files.form_id,
                                progress: 0,
                                index: index,
                                path_preview: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
                            };
                            // console.log(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)))
                            this.addFile(item); // add file into store
                            await this.uploadOneFileToServerV2(result.uid, file, item.id);// Upload and update status, progress into store
                        }
                    )
                }
            })
        )
    })
    async uploadOneFileToServer(user_id: string, file: File, id: string) {
        const file_size = file.size;
        try {
            const res = await this.mediaSV.startUploadForMultiPart(file.name, file_size);
            if (res.success) {
                // console.log(res);
                const file_name = res.result.new_file_name;
                const upload_id = res.result.upload_id;
                const chunk_size = 10 * 1024 * 1024; // 10Mb
                const chunk_count = Math.floor(file_size / chunk_size) + 1;
                const array_file_parts: { part_number: number; file_blob: any; }[] = [];
                for (let upload_count = 1; upload_count < chunk_count + 1; upload_count++) {
                    const start = (upload_count - 1) * chunk_size;
                    const end = upload_count * chunk_size;
                    const file_blob = upload_count < chunk_count ? file.slice(start, end) : file.slice(start);
                    array_file_parts.push({
                        part_number: upload_count,
                        file_blob: file_blob,
                    });
                }
                // get links to upload
                const promises_get_presigned_url: any[] = [];
                const promises_data_parts: any = [];
                array_file_parts.forEach(item => {
                    promises_get_presigned_url.push(this.mediaSV.getPresignedUrlForMultiPartUpload(file_name, item.part_number, upload_id))
                })
                const result_from_promises = await Promise.all(promises_get_presigned_url);
                result_from_promises.forEach((item, index) => {
                    if (item.success != true) {
                        this.updateFile({
                            id: id,
                            changes: { status: 'failed' }
                        });
                    } else {
                        const pre_signed_url = item.result;
                        promises_data_parts.push({ pre_signed_url, file_blob: array_file_parts[index].file_blob, part_number: array_file_parts[index].part_number });
                    }
                })
                const process: any = {};
                const { results } = await PromisePool.for(promises_data_parts)
                    .withConcurrency(10)
                    .handleError((error: any) => { throw new Error(error); })
                    .process(async (data: any) => {
                        const xhr = new XMLHttpRequest();
                        const success: any = await new Promise((resolve) => {
                            xhr.upload.addEventListener('progress', (event) => {
                                if (event.lengthComputable) {
                                    process[data.part_number - 1] = (event.loaded / event.total) * 100;
                                    const progress = this.processingMultiPart(process, chunk_count);
                                    // console.log(`upload progress: ${file_name}`, progress.toFixed(0) + '%');
                                    this.updateFile({
                                        id: id,
                                        changes: { status: 'uploading', progress: Math.round(progress) }
                                    });
                                }
                            });
                            xhr.addEventListener('loadend', () => {
                                if (xhr.readyState === 4 && xhr.status === 200) {
                                    resolve({ Etag: xhr.getResponseHeader('ETag') });
                                }
                            });
                            xhr.open('PUT', data.pre_signed_url, true);
                            xhr.send(data.file_blob);
                        });
                        // console.log("success:", success);
                        return {
                            ETag: success['Etag'],
                            PartNumber: data.part_number,
                        };
                    });
                results.sort(function (a, b) {
                    return a.PartNumber - b.PartNumber;
                });
                const complete_upload = await this.mediaSV.getCompleteMultiPartUpload(file_name, results, upload_id);
                if (complete_upload.success) {
                    // console.log("Completed upload file");
                    const get_type_fname =
                        mime.getType(file_name.split('.').pop().toLowerCase()) || '';
                    if (get_type_fname.match('image.*') && !get_type_fname.match('image/vnd.adobe.photoshop')) {
                        var media_doc = await this.mediaSV.addOneMedia(user_id, res.result.path_file, 'image', false, file_size);

                    } else if (get_type_fname.match('application.*')) {
                        var media_doc = await this.mediaSV.addOneMedia(user_id, res.result.path_file, 'file', false, file_size);
                    } else if (get_type_fname.match('font.*')) {
                        var media_doc = await this.mediaSV.addOneMedia(user_id, res.result.path_file, 'font', false, file_size);
                    } else {
                        var media_doc = await this.mediaSV.addOneMedia(user_id, res.result.path_file, 'file', false, file_size);
                    }
                    this.updateFile({
                        id: id,
                        changes: { status: 'completed', path_preview: res.result.path_file.thumb.url, media_id: media_doc.id, media_ref: media_doc }
                    });
                    return complete_upload;
                } else {
                    this.updateFile({
                        id: id,
                        changes: { status: 'failed' }
                    });
                    return complete_upload;
                }
            } else {
                console.log(res);
                throw new Error(res.err);
            }
        } catch (err: any) {
            throw new Error(err);
        }
    }
    async uploadOneFileToServerV2(user_id: string, file: File, id: string) {
        const file_size = file.size;
        const file_name: any = file.name;
        const res = await this.mediaSV.getLinkUpload(file.name, file_size);
        // console.log(res);
        if (res.success) {
            const xhr = new XMLHttpRequest();
            const success: any = await new Promise((resolve) => {
                xhr.upload.addEventListener('progress', (event) => {
                    // console.log(parseInt(((event.loaded / event.total) * 100).toFixed(2)));
                    if (event.lengthComputable) {
                        this.updateFile({
                            id: id,
                            changes: { status: 'uploading', progress: Math.round((100 * event.loaded) / (event.total)) }
                        });
                    }
                });
                xhr.addEventListener('loadend', async () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        resolve(true);
                    }
                });
                xhr.addEventListener('error', (event) => {
                    console.log(event);
                    // update item

                });
                xhr.open('PUT', res.data.upload_link, true);
                xhr.send(file);
            });
            if (success) {
                const get_type_fname = mime.getType(file_name.split('.').pop().toLowerCase()) || '';
                // console.log(get_type_fname, get_type_fname.match('image/svg+xml'));
                let type_temp = '';
                if (get_type_fname.match('image.*') && !get_type_fname.match('image/vnd.adobe.photoshop') && get_type_fname !== 'image/svg+xml') {
                    var media_doc = await this.mediaSV.addOneMediaV2(user_id, res.data.path_file, 'image', false, file_size);
                    type_temp = 'image';
                } else if (get_type_fname.match('application.*')) {
                    var media_doc = await this.mediaSV.addOneMediaV2(user_id, res.data.path_file, 'file', false, file_size);
                    type_temp = 'file';
                } else if (get_type_fname.match('font.*')) {
                    var media_doc = await this.mediaSV.addOneMediaV2(user_id, res.data.path_file, 'font', false, file_size);
                    type_temp = 'font';
                } else {
                    var media_doc = await this.mediaSV.addOneMediaV2(user_id, res.data.path_file, 'file', false, file_size);
                    type_temp = 'file';
                }
                this.updateFile({
                    id: id,
                    changes: {
                        status: 'completed',
                        path_preview: res.data.path_file.original ? res.data.path_file.original.url + '?format=webp&size=175x125' : res.data.path_file.url,
                        media_id: media_doc.id, media_ref: media_doc,
                        type: type_temp
                    }
                });
            }
        } else {
            this.updateFile({
                id: id,
                changes: { status: 'failed', error_message: res.message }
            });
        }
    }
    processingMultiPart(process: any = {}, total_part: number) {
        let total = 0;
        for (const key in process) {
            total += process[key];
        }
        return total / total_part;
    }

    async removeFileState(form_id: string) {
        const list = await this.selectFileByFormId(form_id).pipe(take(1)).toPromise();
        if (list && list.length > 0) {
            list.forEach(l => {
                this.removeFile(l.id);
            })
        }

    }
    async sortByName(form_id: string, sortByName: boolean) {
        let list = await this.selectFileByFormId(form_id).pipe(take(1)).toPromise();
        if (list && list.length > 0) {
            if (sortByName) {
                list = list.sort((a: UploadFile, b: UploadFile) => (a.name > b.name) ? 1 : -1);
            } else {
                list = list.sort((a: UploadFile, b: UploadFile) => (a.name < b.name) ? 1 : -1);
            }

            list.forEach((item, index) => {
                this.updateFile({ id: item.id, changes: { index: index } })
            })
        }
    }
}
