<ng-container *ngIf="{project_data:project_data$ | async} as vm">
  <ng-container *ngIf="vm.project_data">
    <nz-timeline class="[&>.ant-timeline]:pr-3 [&>.ant-timeline]:pl-[26px] [&>.ant-timeline]:pt-6 [&>.ant-timeline>.ant-timeline-item>.ant-timeline-item-head]:rounded-full [&>.ant-timeline>.ant-timeline-item>.ant-timeline-item-head]:p-0 [&>.ant-timeline>.ant-timeline-item>.ant-timeline-item-head]:bg-transparent [&>.ant-timeline>.ant-timeline-item>.ant-timeline-item-content]:ml-8 [&>.ant-timeline>.ant-timeline-item]:pb-3 [&>.ant-timeline>.ant-timeline-item>.ant-timeline-item-tail]:border-gray-300 [&>.ant-timeline>.ant-timeline-item>.ant-timeline-item-tail]:!left-0 [&>.ant-timeline>.ant-timeline-item-last]:!pb-0 dark:[&>.ant-timeline>.ant-timeline-item>.ant-timeline-item-tail]:border-gray-500">
      <ng-container *ngTemplateOutlet="comment_item; context: {comment: comment}"></ng-container>

      <ng-container *ngIf="list_reply$ | async as list_reply">
        <ng-container *ngIf="list_reply && list_reply.length > 0">
          <ng-container *ngFor="let reply of list_reply; let i=index">
            <ng-container *ngTemplateOutlet="comment_item; context: {comment: reply}"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #comment_item let-comment_temp="comment">
        <nz-timeline-item [nzDot]="user_template">
          <div class="group">
            <div>
              <div class="flex items-center justify-between space-x-3 mb-1">
                <div class="flex items-center space-x-3">
                  <penji-user-item-compress [user_id]="comment_temp.owner_id" [show_user_name]="true"></penji-user-item-compress>
                  <p class="text-sm text-gray-500 dark:text-gray-200">{{comment_temp.created_at?.toDate() | date:'shortDate'}}</p>
                </div>
                <p *ngIf="comment_temp.number_index" class="w-6 h-6 rounded-full text-white bg-blue-500 text-sm flex justify-center items-center p-0.5">{{comment_temp.number_index}}</p>
              </div>
              <div *ngIf="check_id !== comment_temp.id; else is_id">
                <penji-quill-view-html class="[&_.ql-container]:!text-[14px]" [description]="comment_temp.content"></penji-quill-view-html>
              </div>
              <ng-template #is_id>
                <quill-editor [preserveWhitespace]="true" [placeholder]="'Edit'" customToolbarPosition="bottom" class="w-full bg-white [&>.ql-container]:border-gray-200 [&>.ql-container>.ql-editor]:!text-[14px]" [ngModel]="comment_temp.content" (onContentChanged)="onEditorContentChanged($event)">
                  <div quill-editor-toolbar class="!pl-2 !pr-0 !py-1.5 bg-gray-100 !border-t-0 !border-gray-200 dark:bg-gray-600">
                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                      <button class="ql-link"></button>
                      <button class="ql-list" value="ordered"></button>
                      <button class="ql-list" value="bullet"></button>
                      <select class="ql-align" [title]="'Aligment'">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                      </select>
                      <select class="ql-color" [title]="'Color'">
                      </select>
                      <select class="ql-background" [title]="'BackgroundColor'">
                      </select>
                    </span>
                  </div>
                </quill-editor>
              </ng-template>
            </div>
            <div class="action py-1" *ngIf="vm.project_data && vm.project_data.in_queue === false && vm.project_data.status && vm.project_data.status < 6">
              <div class="flex space-x-4 justify-end {{check_id === comment_temp.id ? 'hidden' : ''}}">
                <a class="items-center inline-flex !underline !text-red-600" nz-popconfirm nzPopconfirmTitle="Are you sure delete this comment" nzPopconfirmPlacement="bottom" (nzOnConfirm)="deleteComment(comment_temp)" (nzOnCancel)="cancel()">Delete</a>
                <a class="items-center inline-flex !underline !text-blue-500" (click)="editComment(comment_temp)">Edit</a>
              </div>
              <div class="flex space-x-4 justify-end {{check_id !== comment_temp.id ? 'hidden' : ''}}">
                <a class="items-center inline-flex !underline !text-gray-500" (click)="cancelEdit()">Cancel</a>
                <a  class="items-center inline-flex !underline !text-blue-500" (click)="onEditComment(comment_temp)">Save</a>
              </div>
            </div>
          </div>
        </nz-timeline-item>
        <ng-template #user_template>
          <penji-user-item-compress [user_id]="comment_temp.owner_id" [show_user_avatar]="true"></penji-user-item-compress>
        </ng-template>
      </ng-template>
    </nz-timeline>
  </ng-container>
</ng-container>

