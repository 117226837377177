import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthStore } from '@penji/shared/auth/data-access';
import { DrawerService, Ticket, TicketCategory, TicketSubCategory } from '@penji/shared/data-access';
import { TicketCateStoreService, TicketStoreService } from '@penji/team/ticket/data-access';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-ticket-cate-item',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzToolTipModule
  ],
  templateUrl: './ticket-cate-item.component.html',
  styleUrls: ['./ticket-cate-item.component.scss'],
})
export class TicketCateItemComponent implements OnChanges {
  @Input() ticket!: Ticket;
  @Input() clickable = false;
  @Input() font_size = '14px';
  @Input() font_weight = 400;

  drawerSV = inject(DrawerService);
  ticketCateStore = inject(TicketCateStoreService);
  ticketStore = inject(TicketStoreService);
  private readonly authStore = inject(AuthStore);

  ticketItem$!: Observable<TicketSubCategory | TicketCategory | undefined>;
  auth_id$ = this.authStore.auth_id$;

  ngOnChanges(): void {
    if (this.ticket && this.ticket.ticket_sub_category_id)
      this.ticketItem$ = this.ticketCateStore.getOneTicketCate(this.ticket.ticket_sub_category_id);
  }
  openDrawer(event: any, ticket: Ticket, uid?: string) {
    if (!(event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      const temp = {
        type: 'ticket',
        id: ticket.id
      }
      this.drawerSV.openDrawer(temp);
      this.updateTicket(ticket, uid);
    }
  }
  updateTicket(ticket: Ticket, uid?: string) {
    if (ticket && uid) {
      if (!ticket.viewer?.includes(uid)) {
        const ticket_change = { ...ticket, viewer: [...(ticket.viewer || []), uid] };
        this.ticketStore.updateTicket$(ticket_change);
      }
    }
  }
}
