/* eslint-disable @typescript-eslint/no-empty-interface */
import { DocumentData, DocumentReference, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { EntityState } from '@ngrx/entity';

export class Discussion {
  id!: string;
  array_link?: any[];
  client_team_id?: string;
  content?: string;
  created_at!: Timestamp;
  date!: string | null;
  design_id?: string;
  designer_id?: string;
  discussion_design_id?: string;
  doc!: QueryDocumentSnapshot<DocumentData>;
  email_trigger_key!: string;
  feedback?: string;
  feedback_type?: string;
  group?: string;
  hidden!: boolean;
  id_link?: string;
  late?: boolean;
  late_time_seconds?: number;
  link?: DocumentReference;
  media_array?: Array<{type: string, link: DocumentReference}>;
  list_fonts?: any[];
  list_mention?: any[];
  log_type = 'discussion';
  message_to_client?: string;
  multiple_link?: boolean;
  name?: string;
  number_index?: number;
  owner_id?: string;
  owner_ref?: DocumentReference;
  payload_type?: string;
  project_id?: string;
  project_ref?: DocumentReference;
  question_schedule?: boolean;
  realtime?: boolean;
  reason?: [];
  reject?: boolean;
  remove?: boolean;
  source?: DocumentReference;
  spelling_words?: any[];
  star?: number;
  state_type?: string;
  ticket_id?: string;
  total?: number;
  total_index?: number;
  type!: string;
  updated_at?: Timestamp;
  user_id?: string;
  user_ref?: DocumentReference;
  verify_progress?: boolean;
  verify_type?: number;
  checked?: boolean;
  upload_type?: string;
  parent_id?:string;
  pin_by?: string;
}

export interface DiscussionState extends EntityState<Discussion>{
  loading: boolean;
  error: string;
  anchor_id: string
}

