import { Injectable, inject } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Membership } from '../models/membership.model';
import { EMPTY, Observable, catchError, of } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
    providedIn: 'root'
})
export class MembershipService extends LogService<Membership>{

    nzMessageService = inject(NzMessageService);
    
    getMembershipDetail(membership_id: string) {
        return this.afs.doc<Membership>(`membership/${membership_id}`).valueChanges({ idField: 'id' }).pipe(
            catchError(() => EMPTY)
        );
    }
    async getListMembership(limit: number = 20, where_query?: Array<WhereQueryInterface>) {
        try {
            let query: Query = this.afs.firestore.collection('membership');
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }

            if (limit != -1)
                query = query.limit(limit);

            return query.get().then(querySnapshot => {
                const list: Array<Membership> = [];
                querySnapshot.forEach((doc: any) => {
                    const data = doc.data() as Membership;
                    data.id = doc.id;
                    data.doc = doc;
                    list.push(data);
                });
                return list;
            }).catch(error => {
                console.log(error);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }
    getListMembershipRealTime(limit: number = 20, where_query?: Array<WhereQueryInterface>) {
        try {
            let query: Query = this.afs.firestore.collection('membership');

            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            } else {
                query = query.limit(10);
            }
            if (limit != -1) query = query.limit(limit);

            return new Observable<Membership[]>(observ => {
                return query.onSnapshot(
                    querySnapshot => {
                        const list: Array<Membership> = [];
                        querySnapshot.forEach((doc) => {
                            const data = doc.data() as Membership;
                            data.id = doc.id;
                            data.doc = doc;
                            list.push(data);
                        });
                        return observ.next(list);
                    },
                    error => {
                        observ.error(error);
                    })
            });
        } catch (error) {
            console.log(error);
            return of([]);
        }
    }
    getFirstMembershipByTeam(client_team_id: string) {
        try {
            return this.afs.firestore.collection('membership')
                .where('team_id', '==', client_team_id)
                .orderBy('created_at', 'desc')
                .limit(1)
                .get().then(snapshot => {
                    const list: Array<Membership> = [];
                    snapshot.forEach(doc => {
                        const membership = doc.data() as Membership;
                        membership.id = doc.id;
                        list.push(membership);
                    })
                    return list;
                })
        } catch (error) {
            console.log(error);
            return Promise.resolve([]);
        }

    }

    updateMembership(data: Membership) {
        return this.afs.collection('membership')
          .doc(data.id)
          .update(Membership.parseObject(data))
          .then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ...new Membership, ...data };
            this.createLog();
            this.nzMessageService.success('Membership successfully updated!');
            return { flag: true, message: 'Membership successfully updated!' };
          })
          .catch((err) => {
            console.log(err);
            this.nzMessageService.error(err.message);
            return { flag: false, message: err.message };
          });
      }
}
