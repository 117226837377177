/* eslint-disable  */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { ApiService, Project, ProjectService, WhereQueryInterface } from '@penji/shared/data-access';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { EMPTY, Observable, catchError, combineLatest, distinctUntilChanged, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { Params } from '@angular/router';

interface ProjectState extends EntityState<Project> {
  loading: boolean,
  error: string,
  disable_previous: boolean,
  disable_next: boolean,
  project_data: Project
}
const adapter = createEntityAdapter<Project>();
const initialState: ProjectState = adapter.getInitialState({
  loading: false,
  error: '',
  disable_previous: false,
  disable_next: false,
  project_data: {} as Project
})

@Injectable()
export class ProjectsStoreService extends ComponentStore<ProjectState> {
  private readonly projectSV = inject(ProjectService);
  private readonly apiSV = inject(ApiService);
  private readonly customerTeamStore = inject(CustomerTeamStoreService);
  constructor() { super(initialState); }
  limit = 20;
  readonly data$ = this.select(s => {
    const list = Object.values(s.entities) as Project[];
    return list;
  });
  readonly project_data$ = this.select((s) => s.project_data);

  readonly loading$ = this.select(s => s.loading);
  readonly error$ = this.select((s) => s.error);
  readonly disable_previous$ = this.select((s) => s.disable_previous);
  readonly disable_next$ = this.select((s) => s.disable_next);

  loadProjects$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
      }),
      switchMap((params: any) => {
        this.limit = params.limit ?? this.limit;
        // console.log(params);
        return this.projectSV.listProject(this.limit, params.where_query, params.start_after, params.end_before, params.order_by, params.order_desc).then(rs => {
          if (rs && rs.length > 0) {
            const promise = [
              this.projectSV.listProject(1, params.where_query, undefined, rs[0].id, params.order_by, params.order_desc),
              this.projectSV.listProject(1, params.where_query, rs[rs.length - 1].id, undefined, params.order_by, params.order_desc)
            ]
            return combineLatest(of(rs), Promise.all(promise)).pipe(
              map(([rs, before_after]) => {
                if (before_after[0] && before_after[0].length > 0) {
                  this.patchState({ disable_previous: false });
                } else {
                  this.patchState({ disable_previous: true });
                }
                if (before_after[1] && before_after[1].length > 0) {
                  this.patchState({ disable_next: false });
                } else {
                  this.patchState({ disable_next: true });
                }
                return rs;
              })
            )
          } else {
            return of([] as Project[]);
          }
        }).catch(err => {
          console.log(err);
          return of([] as Project[]);
        });
      }),
      switchMap(rs => rs),
      map(final_list => {
        if (final_list && final_list.length > 0) {
          this.patchState({ loading: false });
        }
        if (final_list.length == 0 || final_list.length < this.limit) {
          this.patchState({ loading: false });
          if (final_list.length == 0) {
            this.patchState({ disable_previous: true, disable_next: true });
          }
        }
        this.setState((state) => adapter.setAll(final_list, state));
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false });
        return EMPTY;
      })
    );
  })
  loadProjectsForRealTime$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
        this.setState(s => adapter.removeAll(s));
      }),
      switchMap((params: any) => {
        // console.log(params);

        return this.projectSV.getListProjectRealTime(-1, params.where_query, undefined, undefined, params.order_by, params.order_desc, params.not_order_by);
      }),
      map(list => {
        this.patchState({ loading: false, error: '' });
        list.forEach(item => {
          if (item.action === 'added' || item.action === 'modified') {
            this.setState(state => adapter.upsertOne(item, state));
          } else {
            this.setState(state => adapter.removeOne(item.id!, state));
          }
        })
        return list;
      })
    );
  })

  getProjectDetail$ = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      distinctUntilChanged((prev, curr) => prev['project_id'] === curr['project_id']),
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((params: Params) => {
        // console.log(params);
        return this.projectSV.getProjectDetail(params['project_id']).pipe(
          switchMap(rs => {
            // console.log(rs);
            if (rs) {
              this.setState(state => {
                return { ...state, project_data: rs as Project, loading: false, error: '' };
              })
              return EMPTY;
            } else {
              this.patchState({ loading: true, error: 'cannot update the project!' });
              return EMPTY;
            }
          })
        )
      })
    )
  });
  createProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project: Project) => {
        return this.projectSV.addProject(project);
      }),
      map(rs => {
        // console.log(rs);

        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state)); // add to List
          this.setState(state => {
            return { ...state, project_data: rs.data, loading: false }; // set project detail
          })
          this.patchState({ loading: false });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
  updateProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((project: Project) => {
        // console.log('update project: ', project);
        return combineLatest(this.projectSV.updateProject(project), of(project));
      }),
      map(([rs, project]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({ id: project.id!, changes: project }, state)); // update in List
          this.setState(state => {
            return { ...state, project_data: { ...state.project_data, ...project } as Project, loading: false }; // update project detail
          })
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
  deleteProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project: Project) => {
        return combineLatest(this.projectSV.deleteProject(project), of(project));
      }),
      map(([rs, project]) => {
        if (rs.flag) {
          project.status = 8;
          this.setState((state) => adapter.updateOne({ id: project.id!, changes: project }, state));
          this.setState(state => {
            return { ...state, project_data: { ...state.project_data, ...project } as Project, loading: false }; // delete project detail
          })
          this.patchState({ loading: false });
        } else {
          console.log(rs);
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
  completeProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((project: Project) => {
        return combineLatest(this.projectSV.moveToCompleteProjectV2(project), of(project));
      }),
      map(([rs, project]) => {
        if (rs.flag) {
          this.setState(state => {
            return { ...state, project_data: { ...state.project_data, ...project } as Project, loading: false }
          })
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateProjectDragAndDrop$ = this.effect((list$: Observable<Project[]>) => {
    return list$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((list: Project[]) => {
        // console.log('update project: ', list);
        const listChange: any = [];
        list.forEach(item => {
          listChange.push({ id: item.id, index: item.index })
        })
        // console.log(listChange);
        return combineLatest(this.projectSV.updateProjects(listChange), of(listChange));
      }),
      map(([rs, list]) => {
        if (rs.flag) {
          // console.log(list);
          this.setState(s => adapter.updateMany(list, s));
          this.patchState({ loading: false });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  moveProject(project_id_in: string, project_id_out?: string) {
    return this.projectSV.moveProjectV2(project_id_in, project_id_out);
  }

  removeProjectFromList$ = this.effect((project_id$: Observable<string>) => {
    return project_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project_id: string) => {
        this.setState((state) => adapter.removeOne(project_id, state));
        this.patchState({ loading: false });
        return EMPTY;
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  })

  searchProject$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap(params => {
        if (params['keyword']) {
          return this.customerTeamStore.team_data$.pipe(
            switchMap(team => {
              if (team && team.id)
                return this.projectSV.searchProjectAlgoliaForClient(team.id, params['keyword']);
              else {
                return of([]);
              }
            })
          )
        }
        return of([]);
      }),
      map(list => {
        this.setState(state => adapter.setAll(list, state));
        this.patchState({ loading: false, error: '' });
        return list;
      })
    );
  })
}
