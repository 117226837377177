import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AuthStore } from '@penji/shared/auth/data-access';
import * as userActions from '@penji/shared/data-access';
import { DrawerService, ImageSize, UserSimple } from '@penji/shared/data-access';
import { MemberCheckinStatusByUidComponent } from '@penji/team/member/member-ui/member-checkin-status-by-uid';
import { UserAvatarComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-avatar';
import { UserNameComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-name';
import { UserRoleComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-role';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Observable, Subject, map, takeUntil } from 'rxjs';

@Component({
  selector: 'penji-user-item-compress',
  standalone: true,
  imports: [
    CommonModule,
    UserAvatarComponent,
    UserNameComponent,
    NzToolTipModule,
    MemberCheckinStatusByUidComponent,
    UserRoleComponent
  ],
  templateUrl: './user-item-compress.component.html',
  styleUrls: ['./user-item-compress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserItemCompressComponent implements OnChanges {

  @Input() user_id?: string;
  @Input() show_user_name = false;
  @Input() show_user_avatar = false;
  @Input() show_user_available_status = false;
  @Input() user_classes = 'text-gray-500';
  @Input() show_user_role = false;

  @Input() user_image_size: ImageSize = 'sm';
  @Input() clickable = false;

  private readonly store = inject(Store);
  private readonly authStore = inject(AuthStore);
  private readonly drawerSv = inject(DrawerService);
  modal = inject(NzModalService);

  auth_profile$ = this.authStore.authProfile$;
  user$?: Observable<UserSimple> | undefined;
  take$ = new Subject<void>();

  ngOnChanges() {
    if(this.clickable) {
      this.user_classes = 'text-blue-500';
    }
    if (this.user_id) {
      this.store.dispatch(userActions.loadUserDB({ uid: this.user_id ?? '' }));
      this.user$ = this.store.pipe(
        select(userActions.getUser(this.user_id)),
        takeUntil(this.take$),
        map(user => {
          if (user) this.take$.complete();
          return user;
        })
      );
    }
  }

  openDrawer(event: any, id: string, type: string) {
    if (!(event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      const temp = { type: type, id: id }
      this.drawerSv.openDrawer(temp);
    }
  }


}
