<ng-container *ngIf="{team_data: team_data$ |async , data: data$ | async} as vm">
    <a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click" class="group/wsp wsp-item flex items-center justify-between space-x-2 whitespace-nowrap py-2.5 pl-6 pr-3 border-t bg-gray-50 border-gray-100 hover:[&>*]:text-purple-600 transition-all duration-300 overflow-hidden dark:bg-gray-500 dark:border-gray-500 dark:hover:[&>*]:!text-blue-500">
        <ng-container *ngFor="let item of vm.data">
            <penji-workspace-item *ngIf="item?.team_id === vm.team_data?.id" class="group-hover/wsp:[&>*]:!text-purple-600 dark:group-hover/wsp:[&>*]:!text-blue-500"
                [team_id]="item?.team_id"></penji-workspace-item>
        </ng-container>
        <span class="material-icons-outlined text-[20px] group-hover/wsp:!text-purple-600 dark:group-hover/wsp:!text-blue-500 transition-all duration-200">
            chevron_right
        </span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable class="rounded-lg py-0 [&>li]:p-3 hover:[&>li]:bg-gray-100
          [&>li:first-of-type]:rounded-[.25rem_.25rem_0_0] [&>li:last-of-type]:rounded-[0_0_.25rem_.25rem] [&>li]:border-gray-100 [&>li]:border-[1px] [&>li:not(:first-of-type)]:border-t-none dark:hover:[&>li]:bg-gray-500 dark:[&>li]:border-gray-500
          ">
            <ng-container *ngFor="let item of vm.data">
                <li nz-menu-item *ngIf="item?.team_id !== vm.team_data?.id">
                    <penji-workspace-item [team_id]="item?.team_id"></penji-workspace-item>
                </li>
                <li nz-menu-item *ngIf="item?.team_id === vm.team_data?.id" (click)="editWorkspace(item)">
                    <div class="flex items-center font-medium">
                        <span class="material-icons-outlined text-gray-400 dark:text-gray-200 mr-4">edit</span> Edit workspace
                    </div>
                </li>
            </ng-container>

            <!-- <li nz-menu-item (click)="addWorkspace()">
                <div class="flex items-center font-medium">
                    <span class="material-icons-outlined text-gray-400 mr-4">add</span> New workspace
                </div>
            </li> -->

        </ul>
    </nz-dropdown-menu>
</ng-container>
