/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Params } from '@angular/router';
import { environment } from '@penji/shared/environments';
import * as firebase from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, catchError, map, of, retry, switchMap, throwError } from 'rxjs';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Project } from '../models/project.model';
import { LogService } from './log.service';
import { BrandService } from './brand.service';
import { genericAction } from '../models/log.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends LogService<Project>{
  private api_url = environment.api_algolia;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  nzMessageSV = inject(NzMessageService);
  brandSV = inject(BrandService);
  apiSV = inject(ApiService);

  searchProjectAlgolia(keyword: string) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          return this.http.post(`${this.api_url}/search-project`, { keyword }, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      }),
      map((result: any) => {
        // console.log(result);
        if (result)
          return result.data.hits as Project[];
        else
          return [];
      }))
  }
  searchProjectAlgoliaForClient(team_id: string, keyword: string) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          const filter = { team_id, keyword } as any;
          // console.log(filter);
          return this.http.post(`${this.api_url}/filter-project`, filter, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      }),
      map((result: any) => {
        // console.log(result);
        if (result)
          return result.data.hits as Project[];
        else
          return [];
      }))
  }
  getListProjectAlgolia(filter_obj: Params) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          const params = { ...filter_obj };
          // mặc định cho inqueue = false pause = false
          // params['in_queue'] = false;
          // params['pause'] = false;
          // params['pending_review'] = false;
          // params['verify_progress'] = false;


          for (const key in params) {
            if (key === 'type_complete') {
              params[key] = parseInt(params[key]);
            } else if (key === 'status') {
              params[key] = params[key].split(',');
            } else if (key === 'status_addition') {
              if (params[key].includes('3.6') && params[key].includes('3.7')) {
                delete params['status_addition'];
                params['pending_review'] = true;
              } else if (params[key].includes('3.6')) {
                delete params['status_addition'];
                params['pending_review'] = true;
                params['verify_progress'] = false;
              } else if (params[key].includes('3.7')) {
                delete params['status_addition'];
                params['pending_review'] = true;
                params['verify_progress'] = true;
              } else if (params[key].includes('4.1')) {
                delete params['status_addition'];
                params['in_queue'] = true;
              } else if (params[key].includes('4.2')) {
                delete params['status_addition'];
                params['pause'] = true;
              } else {
                params[key] = params[key].split(',');
              }

            }
            else if (key === 'team_designer_id') {
              params[key] = params[key].split(',');
            }
          }
          // console.log(params);
          return this.http.post(`${this.api_url}/filter-project`, params, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      }))
  }
  async listProject(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string,
    order_by?: string,
    order_desc: boolean = true,
    not_order_by: boolean = false) {
    try {
      let query: Query = this.afs.firestore.collection('project_v2');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      if (!not_order_by) {
        if (order_by) {
          // console.log(order_by)
          const temp_order_by = order_by.split(',');
          temp_order_by.forEach(item => {
            query = query.orderBy(item, order_desc ? 'desc' : 'asc');
          })
        } else {
          query = query.orderBy('created_at', 'desc');
        }
      }
      // console.log(query);
      if (start_after) {
        const doc = await this.afs.firestore.doc(`project_v2/${start_after}`).get();
        query = query.startAfter(doc);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`project_v2/${end_before}`).get();
        query = query.endBefore(doc);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<Project> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Project;
          data.id = doc.id;
          data.doc = doc;
          data.action= 'added';
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  getListProjectRealTime(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    order_by?: string,
    order_desc: boolean = true,
    not_order_by: boolean = false) {
    // console.log(order_by, not_order_by)
    try {
      let query: Query = this.afs.firestore.collection('project_v2');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          if (q.field_value === undefined)
            return;
          else
            query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      } else {
        query = query.limit(10)
      }
      if (!not_order_by) {
        if (order_by) {
          // console.log(order_by)
          const temp_order_by = order_by.split(',');
          temp_order_by.forEach(item => {
            query = query.orderBy(item, order_desc ? 'desc' : 'asc');
          })
        } else {
          query = query.orderBy('created_at', 'desc');
        }
      }

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }
      // console.log(query);
      return new Observable<Project[]>(observ => {
        return query.onSnapshot(
          querySnapshot => {
            const list: Array<Project> = [];
            querySnapshot.docChanges().forEach((change) => {
              const data = change.doc.data() as Project;
              data.id = change.doc.id;
              data.doc = change.doc;
              data.action = change.type;
              list.push(data);
            });
            // console.log(list);
            return observ.next(list);
          },
          error => {
            observ.error(error);
          })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  getProjectDetail(project_id: string) {
    // console.log(project_id);
    if (project_id) {
      return this.afs.doc<Project>(`project_v2/${project_id}`).valueChanges().pipe(map(rs => {
        return { ...rs, id: project_id } as Project;
      }));
    }
    return of({} as Project);
  }
  async getProjectDetailOnce(project_id: string) {
    try {
      return await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(res => ({ id: res.id, ...res.data() } as Project))
    } catch (error) {
      console.log(error);
      return Promise.resolve({ ...new Project});
    }
  }

  addProject(project: Project) {
    // console.log(project);
    try {
      const _project = { ...project, v3 : true , created_at: firebase.Timestamp.now() }
      if (_project.payment_v2) {
        return this.afs.collection(`project_v2`).add(_project).then(rs => {
          this.log_model.action = 'create';
          this.log_model.data = { ... new Project, ...project, project_id: rs.id };
          this.createLog();
          this.nzMessageSV.success('Project successfully added!');

          if (project.brand_id) {
            this.brandSV.addProjectToBrand(project.team_id, rs.id, project.brand_id, project.owner_id);
          }
          // console.log(rs);

          return ({ flag: true, message: 'Project successfully added!', data: { ..._project, id: rs.id } as Project });
        }).catch((err: any) => {
          console.log(err);

          return ({ flag: false, message: err.message as string, data: {} as Project });
        });
      } else {
        return this.afs.collection(`team/${project.team_id}/project`).add(_project).then(rs => {
          this.log_model.action = 'create';
          this.log_model.data = { ... new Project, ...project, project_id: rs.id };
          this.createLog();
          this.nzMessageSV.success('Project successfully added!');
          // console.log(rs);

          return ({ flag: true, message: 'Project successfully added!', data: { ..._project, id: rs.id } as Project });
        }).catch((err: any) => {
          console.log(err);
          return ({ flag: false, message: err.message as string, data: {} as Project });
        });
      }
    } catch (err) {
      console.log(err);

      return Promise.resolve({ flag: false, message: err as string, data: {} as Project });
    }

  }
  async updateProject(project: Project, log_action?: genericAction) {
    delete project.temp_overdue;
    // console.log(project);
    const project_old = await this.getProjectDetailOnce(project.id!);
    delete project.doc;
    if (project.created_at) {
      delete project.created_at;
    }
    if (project.time_change) {
      delete project.time_change;
    }
    project.project_id = project.id;
    if (project_old.payment_v2) {
      return this.afs.doc(`project_v2/${project.id}`).update({ ...project }).then(() => {
        this.log_model.action = log_action ? log_action : 'update';
        this.log_model.data = { ... new Project, ...project };
        this.createLog();
        this.nzMessageSV.success('Project successfully updated!');

        if (project.brand_id) {
          this.brandSV.addProjectToBrand(project.team_id, project.id!, project.brand_id, project.owner_id);
        }

        return ({ flag: true, message: 'Project successfully updated!' });
      }).catch((err: any) => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
    } else {
      return this.afs.doc(`team/${project.team_id}/project/${project.id}`).update({ ...project }).then(() => {
        this.log_model.action = log_action ? log_action : 'update';
        this.log_model.data = { ... new Project, ...project };
        this.createLog();
        this.nzMessageSV.success('Project successfully updated!');
        if (project.brand_id) {
          this.brandSV.addProjectToBrand(project.team_id, project.id!, project.brand_id, project.owner_id);
        }
        return ({ flag: true, message: 'Project successfully updated!' });
      }).catch((err: any) => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
    }
  }
  async updateProjects(projects: Project[]) {
    const batch = this.afs.firestore.batch();
    projects.forEach(item => {
      batch.update(this.afs.firestore.doc(`project_v2/${item.id}`), { ...item, project_id: item.id });
    })
    return await batch.commit().then(() => {
      //console.log('Projects successfully updated!');
      return ({ flag: true, message: 'Projects successfully updated!' });
    }).catch((err: any) => {
      console.log(err);
      return ({ flag: false, message: err.message });
    });
  }


  async deleteProject(project: Project) {
    const project_old = await this.getProjectDetailOnce(project.id!);
    delete project.doc;
    project.project_id = project.id;
    if (project_old.payment_v2) {
      return this.afs.doc(`project_v2/${project.id}`).update({ status: 8 }).then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ... new Project, ...project };
        this.createLog();
        this.nzMessageSV.success('Project successfully deleted!');
        return ({ flag: true, message: 'Project successfully deleted!' });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message });
      });
    } else {
      return this.afs.doc(`team/${project.team_id}/project/${project.id}`).update({ status: 8 }).then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ... new Project, ...project };
        this.createLog();
        this.nzMessageSV.success('Project successfully deleted!');
        return ({ flag: true, message: 'Project successfully deleted!' });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message });
      });
    }
  }
  /**
   * 0: started
   * 0.5: undo started
   * 1.5: question
   * 2: revision
   * 3: pending review
   * 3.5: reject
   * 4: delivered
   * 5: on-hold
   * 6: completed
   *  **/
  async changeStatus(client_team_id: string, project_id: string, status: number) {
    try {
      const data = new Project();
      data.team_id = client_team_id;
      data.id = project_id;
      data.updated_at = firebase.Timestamp.now();
      if (status === 0) { // change to started
        delete data.status;
        data.pending_review = false;
        data.verify_progress = false;
        data.status_addition = 1;
        data.start_design_at = firebase.Timestamp.now();
      } else if (status === 0.5) { // Undo started design
        delete data.status;
        data.pending_review = false;
        data.verify_progress = false;
        data.status_addition = 0;
        data.start_design_at = undefined;
      } else if (status === 1.5) { // change to question
        delete data.status;
        data.pending_review = false;
        data.verify_progress = false;
        data.status_addition = 3;
        data.circle_type = 1;
      } else if (status === 2) { // change to revision
        data.status = 2;
      } else if (status === 3) { // change to pending review
        data.pending_review = true;
        data.verify_progress = false;
        data.status_addition = 0;
      } else if (status === 3.5) { // change to reject
        delete data.status;
        data.pending_review = false;
        data.verify_progress = false;
        data.status_addition = 2;
      } else if (status === 4) { // change to delivered
        data.status = 4;
      } else if (status === 5) { // change to on hold
        data.status = 5;
      } else if (status === 6) { // change to completed
        data.type_complete = 2;
        data.status = 6;
        data.complete_at = firebase.Timestamp.now();
      }
      // console.log(data);

      const kq = await this.updateProject(data, 'change_status');
      return ({ flag: kq.flag, message: kq.message, data: data });
    } catch (err) {
      console.log(err);
      return ({ flag: false, message: err });
    }
  }

  // Client-one-off
  getListProjectActive(client_team_id: string) {
    return this.afs.collection('team').doc(client_team_id).collection<DocumentData>('project', ref => {
      return ref
        .where("pause", "==", false)
        .where('status', '<=', 4)
    }).snapshotChanges().pipe(map(action => action.map(res => {
      const data = res.payload.doc.data() as Project;
      data.id = res.payload.doc.id;
      data.doc = res.payload.doc;
      data.action = res.type;
      return data;
    })));
  }
  getListProjectActiveForWidget(user_id: string, designer_team_id: string) {
    if (!user_id && designer_team_id) {
      return this.afs.collection<DocumentData>('project_v2', ref => {
        return ref
          .where("pause", "==", false)
          .where('status', '<=', 2)
          // .where("assigned", "==", true)
          .where("in_queue", "==", false)
          .where("pending_review", "==", false)
          .where("status_addition", "in", [0, 1, 2])
          .where("assign_team_designer", "==", designer_team_id)
      }).snapshotChanges().pipe(map(action => action.map(res => {
        const data = res.payload.doc.data() as Project;
        data.id = res.payload.doc.id;
        data.doc = res.payload.doc;
        data.action = res.type;
        return data;
      })));
    } else if (user_id && !designer_team_id) {
      return this.afs.collection<DocumentData>('project_v2', ref => {
        return ref
          .where("pause", "==", false)
          .where('status', '<=', 2)
          .where("assigned", "==", true)
          .where("in_queue", "==", false)
          .where("pending_review", "==", false)
          .where("status_addition", "in", [0, 1, 2])
          .where("assign_by", "array-contains", user_id);
      }).snapshotChanges().pipe(map(action => action.map(res => {
        const data = res.payload.doc.data() as Project;
        data.id = res.payload.doc.id;
        data.doc = res.payload.doc;
        data.action = res.type;
        return data;
      })));
    } else if (user_id && designer_team_id) {
      return this.afs.collection<DocumentData>('project_v2', ref => {
        return ref
          .where("pause", "==", false)
          .where('status', '<=', 2)
          .where("assigned", "==", true)
          .where("in_queue", "==", false)
          .where("pending_review", "==", false)
          .where("status_addition", "in", [0, 1, 2])
          .where("assign_team_designer", "==", designer_team_id)
          .where("assign_by", "array-contains", user_id);
      }).snapshotChanges().pipe(map(action => action.map(res => {
        const data = res.payload.doc.data() as Project;
        data.id = res.payload.doc.id;
        data.doc = res.payload.doc;
        data.action = res.type;
        return data;
      })));
    } else {
      return of([new Project]);
    }
  }

  async moveToCompleteProject(project: Project) {
    const batch = this.afs.firestore.batch();
    const data_project = {
      'status': project.status,
      'completed_by': project.completed_by,
      'type_complete': project.type_complete,
      'reason': project.reason,
      'feedback': project.feedback,
      'feedback_type': project.feedback_type,
      'complete_at': firebase.Timestamp.now(),
      'lastest_feedback': project.reason
    }
    batch.update(this.afs.firestore.collection('team').doc(project.team_id).collection('project').doc(project.id), data_project);
    const data = {
      'created_at': firebase.Timestamp.now(),
      'content': 'Marked project as complete',
      'type': 'text',
      'user_id': project.completed_by,
      'user_ref': this.afs.firestore.collection('user').doc(project.completed_by),
      'star': 0,
      'hidden': true,
      'remove': false,
      'feedback_type': project.feedback_type,
      'reason': project.reason,
      'feedback': project.feedback,
      'project_id': project.id,
      'project_ref': this.afs.firestore.collection('team').doc(project.team_id).collection('project').doc(project.id)
    };
    if (project.feedback && project.feedback.trim() != '') {
      data.content += '<br />';
      if (project.reason && project.reason.length > 0) {
        project.reason.forEach(item => {
          data.content += '_ ' + item + '<br />';
        });
      }
      data.content += 'Message from feedback: <br />' + project.feedback;
    }
    batch.set(this.afs.firestore.collection('team').doc(project.team_id).collection('project').doc(project.id).collection('discussion').doc(), data);

    return await batch.commit().then(() => {
      this.nzMessageSV.success('Complete project success!');
      this.log_model.action = 'change_status';
      this.log_model.data = { ... new Project, ...{id: project.id} , ...data_project };
      this.createLog();
      return ({ flag: true, message: 'Complete project success!' });
    }).catch(error => {
      this.nzMessageSV.error(error.messsage);
      return ({ flag: false, message: error.messsage })
    });
  }

  async moveToCompleteProjectV2(project: Project) {
    const batch = this.afs.firestore.batch();
    const data_project = {
      'status': project.status,
      'completed_by': project.completed_by,
      'type_complete': project.type_complete,
      'reason': project.reason,
      'feedback': project.feedback,
      'feedback_type': project.feedback_type,
      'complete_at': firebase.Timestamp.now(),
      'lastest_feedback': project.reason
    }
    batch.update(this.afs.firestore.doc(`project_v2/${project.id}`), data_project);

    const data = {
      'created_at': firebase.Timestamp.now(),
      'content': 'Marked project as complete',
      'type': 'text',
      'user_id': project.completed_by,
      'user_ref': this.afs.firestore.collection('user').doc(project.completed_by),
      'star': 0,
      'hidden': true,
      'remove': false,
      'feedback_type': project.feedback_type,
      'reason': project.reason,
      'feedback': project.feedback,
      'project_id': project.id,
      'project_ref': this.afs.firestore.collection('project_v2').doc(project.id)
    };
    if (project.feedback && project.feedback.trim() != '') {
      data.content += '<br />';
      if (project.reason && project.reason.length > 0) {
        project.reason.forEach(item => {
          data.content += '_ ' + item + '<br />';
        });
      }
      data.content += 'Message from feedback: <br />' + project.feedback;
    }
    batch.set(this.afs.firestore.collection('discussion_v2').doc(), data);

    return await batch.commit().then(() => {
      this.log_model.action = 'change_status';
      this.log_model.data = { ... new Project, ...{id: project.id} , ...data_project };
      this.createLog();
      this.nzMessageSV.success('Complete project success!');
      return ({ flag: true, message: 'Complete project success!' });
    }).catch(error => {
      console.log(error);
      this.nzMessageSV.error(error);
      return ({ flag: false, message: error })
    });
  }


  async getTotalProjectCompleteForMember(uid: string, type_complete: number) {
    try {
      const coll = firebase.collection(firebase.getFirestore(), 'project_v2');
      const q = firebase.query(coll,
        firebase.where("status", "==", 6),
        firebase.where("type_complete", "==", type_complete),
        firebase.where('assign_by', 'array-contains', uid),
        firebase.where('complete_at', '>=', firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 7))))
      );
      const snapshot = await firebase.getCountFromServer(q);
      return snapshot.data().count;
    } catch (error) {
      console.log(error);
      return 0;
    }

  }
  async getTotalProjectCompleteForCustomer(client_team_id: string, type_complete: number) {
    try {
      const coll = firebase.collection(firebase.getFirestore(), 'project_v2');
      const q = firebase.query(coll,
        firebase.where("status", "==", 6),
        firebase.where("type_complete", "==", type_complete),
        firebase.where('team_id', '==', client_team_id),
        firebase.where('complete_at', '>=', firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 7))))
      );
      const snapshot = await firebase.getCountFromServer(q);
      return snapshot.data().count;
    } catch (error) {
      console.log(error);
      return 0;
    }

  }

  getElapsedTime(timer: any) {
    let totalSeconds;
    if (typeof timer == 'number') {
      totalSeconds = Math.floor((new Date().getTime() - timer) / 1000);
    } else {
      totalSeconds = Math.floor((new Date().getTime() - timer.toMillis()) / 1000);
    }

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }
  async getRecentDesignersWorked(client_team_id: string) {
    try {
      const result = await this.afs.firestore.collection('project_v2')
        .where('team_id', '==', client_team_id)
        .where('status', '==', 6)
        .where('project_type', 'in', ['sub_design', 'one_design'])
        .where('in_queue', 'in', [true, false])
        .orderBy('created_at', 'desc')
        .limit(3)
        .get();

      const list = Array<Project>();
      result.forEach(snapshot => {
        const data = snapshot.data() as Project;
        data.id = snapshot.id;
        list.push(data);
      })
      return list;
    } catch (error) {
      console.log(error);
      return Promise.resolve([]);
    }
  }

  async moveProjectV1ToInQueue(client_team_id: string, project_id_in: string) {
    try {
      const batch = this.afs.firestore.batch();
      const listProject = await (await this.afs.firestore.doc(`team/${client_team_id}/team_info/list_project`).get()).data();
      if (listProject) {
        let list_queue = listProject['queue'];
        list_queue.push({ project_id: project_id_in });

        batch.update(this.afs.firestore.doc(`team/${client_team_id}/team_info/list_project`), {
          'queue': list_queue
        });

        batch.set(this.afs.firestore.collection(`team/${client_team_id}/project/${project_id_in}/discussion`).doc(), {
          created_at: firebase.Timestamp.now(),
          type: 'text',
          content: 'Client moved project to <b class="in_queue">In queue</b>',
          hidden: true,
          remove: false,
          star: 0,
          user_id: '8i7FhXui3qU9Q2DWgIOY3t3oV3l1',
          user_ref: this.afs.firestore.doc(`user/8i7FhXui3qU9Q2DWgIOY3t3oV3l1`)
        });

        return await batch.commit().then(() => {
          this.nzMessageSV.success('Project successfully moved!');
          this.log_model.action = 'move_to_inqueue';
          this.log_model.data = { ... new Project, id: project_id_in };
          this.createLog();
          return ({ flag: true, message: 'Project successfully moved!' });
        }).catch(error => {
          this.nzMessageSV.error(error.message);
          return ({ flag: false, message: error.message });
        });
      } else {
        this.nzMessageSV.error('Unable to retrieve active project lists.');
        return ({ flag: false, message: 'Unable to retrieve active project lists.' });
      }
    } catch (error) {
      this.nzMessageSV.error(error as string);
      return ({ flag: false, message: error as string });
    }
  }

  async moveProjectV1ToActive(client_team_id: string, project_id_in: string, project_status_in: number, project_id_out?: string) {
    try {
      const batch = this.afs.firestore.batch();
      const promises = [];
      promises.push(this.afs.firestore.doc(`team/${client_team_id}`).get());
      promises.push(this.afs.firestore.doc(`team/${client_team_id}/team_info/list_project`).get());

      const result = await Promise.all(promises);
      const team_detail = result[0].data();
      const list_project = result[1].data();
      if (team_detail && list_project) {
        const number_list_active = list_project['active_project']['new'].length + list_project['active_project']['awaiting_feedback'].length + list_project['active_project']['revision'].length;
        if (number_list_active < team_detail['number_of_active_project']) {
          let list_new = list_project['active_project']['new'];
          let list_revision = list_project['active_project']['revision'];
          let list_queue = list_project['queue'];

          if (project_status_in != 5) {
            list_new.push({ project_id: project_id_in });
            if (project_status_in != 6) {
              const foundIndex = list_queue.findIndex((x: any) => x.project_id === project_id_in);
              if (foundIndex > -1) {
                list_queue.splice(foundIndex, 1);
              }
            }
          } else {
            list_revision.push({ project_id: project_id_in });
            batch.update(this.afs.firestore.doc(`team/${client_team_id}/project/${project_id_in}`), {
              status: 2
            })
          }

          // console.log(list_new, list_revision, list_queue);

          batch.update(this.afs.firestore.doc(`team/${client_team_id}/team_info/list_project`), {
            'active_project.new': list_new,
            'active_project.revision': list_revision,
            'queue': list_queue
          });

          batch.set(this.afs.firestore.collection(`team/${client_team_id}/project/${project_id_in}/discussion`).doc(), {
            created_at: firebase.Timestamp.now(),
            type: 'text',
            content: 'Client moved project to <b class="active">Active</b>',
            hidden: true,
            remove: false,
            star: 0,
            user_id: '8i7FhXui3qU9Q2DWgIOY3t3oV3l1',
            user_ref: this.afs.firestore.doc(`user/8i7FhXui3qU9Q2DWgIOY3t3oV3l1`)
          });

          return await batch.commit().then(() => {
            this.nzMessageSV.success('Project successfully moved!');
            this.log_model.action = 'move_to_active';
            this.log_model.data = { ... new Project, id: project_id_in };
            this.createLog();
            return ({ flag: true, message: 'Project successfully moved!' });
          }).catch(error => {
            console.log(error);
            
            this.nzMessageSV.error(error.message);
            return ({ flag: false, message: error.message });
          });
        } else {
          let list_new = list_project['active_project']['new'];
          let list_revision = list_project['active_project']['revision'];
          let list_awaiting = list_project['active_project']['awaiting_feedback'];
          let list_queue = list_project['queue'];
          // push new to active
          if (project_status_in !=5 ) {
            list_new.push({ project_id: project_id_in });
          } else {
            batch.update(this.afs.firestore.doc(`team/${client_team_id}/project/${project_id_in}`), {
              status: 2
            })
            list_revision.push({ project_id: project_id_in });
          }

          // remove old from active
          const foundIndex1 = list_new.findIndex((x: any) => x.project_id === project_id_out);
          if (foundIndex1 >= 0) {
            list_new.splice(foundIndex1, 1);
          }
          const foundIndex2 = list_revision.findIndex((x: any) => x.project_id === project_id_out);
          if (foundIndex2 >= 0) {
            list_revision.splice(foundIndex2, 1);
          }
          const foundIndex3 = list_awaiting.findIndex((x: any) => x.project_id === project_id_out);
          if (foundIndex3 >= 0) {
            list_awaiting.splice(foundIndex3, 1);
          }
          // remove new from inqueue, push old to top of inqueue
          if (project_status_in != 5 && project_status_in != 6) {
            const foundIndex4 = list_queue.findIndex((x: any) => x.project_id === project_id_in);
            list_queue.splice(foundIndex4, 1);
          }
          list_queue.unshift({ project_id: project_id_out });

          // update all array
          batch.update(this.afs.firestore.doc(`team/${client_team_id}/team_info/list_project`), {
            'active_project.new': list_new,
            'active_project.revision': list_revision,
            'active_project.awaiting_feedback': list_awaiting,
            'queue': list_queue,
          });

          batch.set(this.afs.firestore.collection(`team/${client_team_id}/project/${project_id_in}/discussion`).doc(), {
            created_at: firebase.Timestamp.now(),
            type: 'text',
            content: 'Client moved project to <b class="active">Active</b>',
            hidden: true,
            remove: false,
            star: 0,
            user_id: '8i7FhXui3qU9Q2DWgIOY3t3oV3l1',
            user_ref: this.afs.firestore.doc(`user/8i7FhXui3qU9Q2DWgIOY3t3oV3l1`)
          });

          // add to penji bot ALAY
          await this.afs.firestore.collection(`team/${client_team_id}/penji_bot_discussion`).add({
            'project_id_out': project_id_out,
            'project_id_in': project_id_in,
            'type': project_status_in === 6 ? 'project_complete_to_active_with_replace' : project_status_in === 5 ? 'project_onhold_to_active_with_replace' : 'project_replace'
          });

          return await batch.commit().then(async () => {
            this.nzMessageSV.success('Project successfully replaced!');
            this.log_model.action = 'move_to_active';
            this.log_model.data = { ... new Project, id: project_id_in };
            await this.createLog();
            if (project_id_out) {
              this.log_model.action = 'move_to_inqueue';
              this.log_model.data = { ... new Project, id: project_id_out };
              this.createLog();
            }
            return ({ flag: true, message: 'Project successfully replaced!' });
          }).catch(error => {
            this.nzMessageSV.error(error.message);
            return ({ flag: false, message: error.message });
          });
        }
      } else {
        this.nzMessageSV.error('Unable to retrieve team details or active project lists.');
        return ({ flag: false, message: 'Unable to retrieve team details or active project lists.' });
      }
    } catch (error) {
      this.nzMessageSV.error(error as string);
      return ({ flag: false, message: error as string });
    }
  }

  async moveProjectV2(project_id_in: string, project_id_out?: string) {
    const rs = await this.apiSV.moveProject(project_id_in, project_id_out);
    if (rs.flag) {
      this.nzMessageSV.success('Project successfully replaced!');
      this.log_model.action = 'move_to_active';
      this.log_model.data = { ... new Project, id: project_id_in };
      await this.createLog();
      if (project_id_out) {
        this.log_model.action = 'move_to_inqueue';
        this.log_model.data = { ... new Project, id: project_id_out };
        this.createLog();
      }
    }
    return rs;
  }

  totalActiveProjectForCustomer(client_team_id: string){
    const where_query: WhereQueryInterface[] = [
      {
        field_name: 'team_id',
        field_operator: '==',
        field_value: client_team_id
      },
      {
        field_name: 'project_type',
        field_operator: '==',
        field_value: 'sub_design'
      },
      {
        field_name: 'in_queue',
        field_operator: '==',
        field_value: false
      },
      {
        field_name: 'status',
        field_operator: 'in',
        field_value: [1, 2, 3, 4]
      }
    ];
    return this.getListProjectRealTime(-1, where_query, undefined, undefined, undefined, undefined, true).pipe(map(list=> list.length));
  }
  totalInqueueProjectForCustomer(client_team_id: string){
    const where_query: WhereQueryInterface[] = [
      {
        field_name: 'team_id',
        field_operator: '==',
        field_value: client_team_id
      },
      {
        field_name: 'project_type',
        field_operator: '==',
        field_value: 'sub_design'
      },
      {
        field_name: 'in_queue',
        field_operator: '==',
        field_value: true
      },
      {
        field_name: 'status',
        field_operator: 'in',
        field_value: [1, 2, 3, 4]
      }
    ];
    return this.getListProjectRealTime(-1, where_query, undefined, undefined, undefined, undefined, true).pipe(map(list=> list.length));
  }
}
