import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MediaRefComponent } from '@penji/client-v3/media/ui/media-ref';
import { WorkspaceStoreService } from '@penji/client-v3/workspace/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { WorkSpace } from '@penji/shared/data-access';
import { UploadFileStoreService } from '@penji/shared/ui/element/upload-file-form/data-access';
import { InputFileFormComponent } from '@penji/shared/ui/element/upload-file-form/ui/input-file-form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'penji-workspace-create-edit-form',
  standalone: true,
  imports: [
    CommonModule,
    NzFormModule,
    NzInputModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    MediaRefComponent,
    InputFileFormComponent
  ],
  templateUrl: './workspace-create-edit-form.component.html',
  styleUrls: ['./workspace-create-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UploadFileStoreService]
})
export class WorkspaceCreateEditFormComponent implements OnInit {

  private readonly authStore = inject(AuthStore);
  private readonly workspaceStore = inject(WorkspaceStoreService);
  auth_id$ = this.authStore.auth_id$;
  dataForm = new UntypedFormGroup({
    'title': new UntypedFormControl('', [Validators.required]),
    'logo': new UntypedFormControl('')
  })
  isLoading$ = new BehaviorSubject<boolean>(false);
  constructor(@Inject(NZ_MODAL_DATA) public workspace: { id: string, team_id: string, title: string, logo: any }) { }
  ngOnInit(): void {
    if (this.workspace) {
      this.dataForm.patchValue({
        'title': this.workspace.title
      })
    }
  }
  onSubmit() {
    // console.log(this.dataForm.value);
    if (this.workspace && this.workspace.team_id) {
      // edit workspace
      const workspace = new WorkSpace();
      workspace.id = this.workspace.id;
      workspace.team_id = this.workspace.team_id;
      workspace.title = this.dataForm.value.title;
      if (this.dataForm.value.logo.length > 0) {
        workspace.logo = this.dataForm.value.logo[0].media_ref;
      }
      this.workspaceStore.updateWorkSpace$(workspace);
    } else {
      // add workspace
      alert('payout page')
      // this.workspaceStore.createWorkSpace$(this.auth_id$.pipe(
      //   map(auth_id => {
      //     const workspace = new WorkSpace();
      //     workspace.user_id = auth_id;
      //     workspace.title = this.dataForm.value.title;
      //     workspace.logo = this.dataForm.value.logo;
      //     return workspace;
      //   })
      // ))
      // this.dataForm.reset();
    }

  }
  // async getFile(data: any) {
  //   console.log(data);
  //   if (data == 'loading') {
  //     this.isLoading$.next(true);
  //   } else {
  //     if (data[0] && data[0].media_ref) {
  //       this.dataForm.patchValue({
  //         'logo': data[0].media_ref
  //       })
  //     } else {
  //       this.dataForm.patchValue({
  //         'logo': null
  //       })
  //     }
  //     this.isLoading$.next(false);
  //   }

  // }
}
